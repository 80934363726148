import React, { useState } from 'react'
import { connect } from 'react-redux'
import types from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row } from '../../../components/container'
import Button from '../../../components/button'
import TitleHeader from '../../../components/header'
import prefetch from '../../../utils/prefetch'
import withRouter from '../../../config/router/withRouter'
import routes from '../../../config/router/routes'
import { useIsMobile } from '../../../utils/hooks'
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg'
import AtlasCover from './AtlasCover'

export const Atlas = ({
  title,
  goStore,
  cover,
  description,
  tags,
  responsable: { name } = {},
  annualPrice,
  biannualPrice,
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const isMobile = useIsMobile()
  const { t } = useTranslation('store')
  return (
    <>
      <TitleHeader
        title={t('store')}
        onTitleClick={goStore}
        RightComponent={() => (
          <Row>
            {isMobile && (
              <Button contained primary onClick={() => setShowMenu(!showMenu)}>
                <MenuIcon fill="white" width="30px" />
              </Button>
            )}
          </Row>
        )}
      />
      <AtlasCover
        cover={cover}
        title={title}
        description={description}
        tags={tags}
        responsable={name}
        biannualPrice={biannualPrice || 0}
        annualPrice={annualPrice || 0}
      />
    </>
  )
}
Atlas.propTypes = {
  title: types.string.isRequired,
  goStore: types.func.isRequired,
  cover: types.string.isRequired,
  description: types.string.isRequired,
  tags: types.arrayOf(types.string).isRequired,
  responsable: types.shape({ name: types.string.isRequired }),
  annualPrice: types.number.isRequired,
  biannualPrice: types.number.isRequired,
}
Atlas.defaultProps = {
  responsable: {},
}

const mapRouterToProps = ({
  match: {
    params: { atlasId: id },
  },
  history: { push },
}) => ({
  id,
  goStore: () => push(routes.store),
})

const mapStateToProps = ({ store }, { id }) => ({
  ...store[id],
})

const mapDispatchToProps = ({ store: { getAtlas } }, { id }) => ({
  getAtlas: () => getAtlas({ id }),
})

export default withRouter(mapRouterToProps)(
  connect(mapStateToProps, mapDispatchToProps)(prefetch(['getAtlas'])(Atlas)),
)
