import AWS from 'aws-sdk'
import S3 from 'aws-sdk/clients/s3'
import uuid from 'uuid/v4'

// TODO: Remove hardcoded creds
const defaultCredentials = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'us-east-2',
}
AWS.config.update(defaultCredentials)
const readFile = file =>
  new Promise(resolve => {
    const fr = new FileReader()
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.readAsArrayBuffer(file)
  })
export const uploadFile = ({ file, progress = () => {}, credentials = defaultCredentials } = {}) =>
  new Promise(async (resolve, reject) => {
    const s3 = new AWS.S3()
    AWS.config.update(credentials)
    const hashName = uuid()
    const key = `gigapan/${hashName}`
    const params = {
      Bucket: process.env.REACT_APP_ASSET_BUCKET,
      Key: key,
      Body: Buffer.from(await readFile(file)),
      ACL: 'public-read',
    }
    s3.upload(params)
      .on('httpUploadProgress', progressInfo => {
        progress(progressInfo)
      })
      .send((err, data) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })

export default { uploadFile }
