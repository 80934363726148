import { toast } from 'react-toastify'
import api from '../../api/index'
import { addLanguage, reloadLanguages } from '../../i18n/instance'

const model = {
  state: {},
  reducers: {
    setAll(state, payload) {
      return payload.reduce(
        (all, a) => ({
          ...all,
          [a.id]: a,
        }),
        {},
      )
    },
    set(state, payload) {
      return {
        ...state,
        [payload.id]: payload,
      }
    },
  },
  effects: dispatch => ({
    async getAll() {
      try {
        const languages = await api.languages.getAll()
        dispatch.languages.setAll(languages)
        return languages
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async create(
      data,
      {
        auth: { token },
      },
    ) {
      try {
        const language = await api.languages.create(data, token)
        dispatch.languages.set(language)
        addLanguage(data.language, data.value)
        return language
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async update(
      data,
      {
        auth: { token },
      },
    ) {
      try {
        const language = await api.languages.update(data, token)
        dispatch.languages.set(language)
        reloadLanguages()
        return language
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async delete(
      { id },
      {
        auth: { token },
      },
    ) {
      try {
        await api.languages.remove(id, token)
        dispatch.languages.getAll()
        reloadLanguages()
        return true
      } catch (e) {
        console.error(e)
        return null
      }
    },
    /*
    async create(
      { email, purchaseId },
      {
        auth: { token, id },
      },
    ) {
      try {
        const user = await api.user.get(token, email)
        const accessCode = await api.accessCode.create(token, {
          accessCode: { purchaseId, responsableId: id, userId: user.id },
        })
        dispatch.accessCode.set(accessCode)
        return accessCode
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async update(
      { email, purchaseId, id },
      {
        auth: { token, id: responsableId },
      },
    ) {
      try {
        const user = await api.user.get(token, email)
        const accessCode = await api.accessCode.update(token, {
          accessCode: {
            purchaseId,
            responsableId,
            userId: user.id,
            id,
          },
          id,
        })
        dispatch.accessCode.set(accessCode)
        return accessCode
      } catch (e) {
        toast.error(`Error al modificar el código de acceso "${id}"`)
        console.error(e)
        return null
      }
    },
    async assign(
      { email, purchaseId, id },
      {
        auth: { token, id: responsableId },
      },
    ) {
      try {
        const accessCode = await api.accessCode.assign(token, {
          accessCode: {
            purchaseId,
            responsableId,
            email,
            id,
          },
          id,
        })
        dispatch.accessCode.set(accessCode)
        return accessCode
      } catch (e) {
        toast.error(`Error al asignar el código de acceso "${id}"`)
        console.error(e)
        return null
      }
    },
    async unassign(
      { id },
      {
        auth: { token },
      },
    ) {
      try {
        const accessCode = await api.accessCode.update(token, {
          accessCode: {
            userId: null,
            id,
          },
          id,
        })
        dispatch.accessCode.set(accessCode)
        return accessCode
      } catch (e) {
        console.error(e)
        return null
      }
    },
    */
  }),
}

export default model
