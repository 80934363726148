import React from 'react'
import types from 'prop-types'
import { useTranslation } from 'react-i18next'
import routes from '../../../../config/router/routes'
import { ReactComponent as IconDescription } from '../../../../assets/icons/description.svg'
import { ReactComponent as IconImage } from '../../../../assets/icons/image.svg'
import { ReactComponent as IconRadio } from '../../../../assets/icons/radio.svg'
import { ReactComponent as IconVideo } from '../../../../assets/icons/video.svg'
import { ReactComponent as IconModel3d } from '../../../../assets/icons/3d.svg'
import withRouter from '../../../../config/router/withRouter'
import { Select } from '../../../../components/form'

const OptionsMenu = ({
  description,
  model,
  radio,
  video,
  img,
  atlasId,
  anatomicMapId,
  path,
  push,
}) => {
  const { t } = useTranslation('atlas')
  const options = [
    {
      Icon: IconDescription,
      text: t('description'),
      path: routes.anatomicMap(atlasId, anatomicMapId),
      itHas: description,
    },
    {
      Icon: IconModel3d,
      text: t('3d'),
      path: routes.anatomicMapModel(atlasId, anatomicMapId),
      itHas: model,
    },
    {
      Icon: IconRadio,
      text: t('radiography'),
      path: routes.anatomicMapRadiography(atlasId, anatomicMapId),
      itHas: radio,
    },
    {
      Icon: IconVideo,
      text: t('videos'),
      path: routes.anatomicMapVideos(atlasId, anatomicMapId),
      itHas: video,
    },
    {
      Icon: IconImage,
      text: t('images'),
      path: routes.anatomicMapImages(atlasId, anatomicMapId),
      itHas: img,
    },
  ].filter(({ itHas }) => itHas)
  return (
    <Select
      value={path}
      onChange={({ path: elementPath }) => push(elementPath)}
      options={options}
    />
  )
}

OptionsMenu.propTypes = {
  description: types.string.isRequired,
  model: types.string.isRequired,
  radio: types.string,
  video: types.string,
  img: types.string,
  atlasId: types.string.isRequired,
  anatomicMapId: types.string.isRequired,
  path: types.string.isRequired,
  push: types.func.isRequired,
}
OptionsMenu.defaultProps = {
  radio: null,
  video: null,
  img: null,
}
const mapRouterToProps = ({
  history: { push },
  location: { pathname },
  match: {
    params: { atlasId, anatomicMapId },
  },
}) => ({
  push,
  path: pathname,
  atlasId,
  anatomicMapId,
})

export default withRouter(mapRouterToProps)(OptionsMenu)
