import React, { useState } from 'react'
import types from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { useTranslation, withTranslation } from 'react-i18next'
import { Container, Row } from '../../components/container'
import TitleHeader from '../../components/header'
import { RouteredAtlasCard as AtlasCard } from './AtlasCard'
import { SearchInput } from '../../components/form'
import prefetch from '../../utils/prefetch'

const SearchContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`
const SearchComponent = ({ search, setSearch }) => {
  const { t } = useTranslation('store')
  return (
    <SearchContainer>
      <SearchInput
        primary
        placeholder={t('atlasTitle')}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
    </SearchContainer>
  )
}

export const Store = ({ atlases, accessCodes }) => {
  const { t } = useTranslation('store')
  const [search, setSearch] = useState('')
  return (
    <>
      <TitleHeader
        title={t('store')}
        RightComponent={<SearchComponent search={search} setSearch={setSearch} />}
      />
      <Container>
        <Row fullWidth wraped justifyContent="space-around">
          {atlases
            .filter(atlas => (atlas.title + atlas.description).match(new RegExp(search, 'i')))
            .map(atlas => (
              <AtlasCard {...atlas} key={atlas.id} purchased={accessCodes[atlas.id]} />
            ))}
        </Row>
      </Container>
    </>
  )
}
Store.propTypes = {
  atlases: types.arrayOf(
    types.shape({
      title: types.string.isRequired,
      sectionsAmount: types.number.isRequired,
      description: types.shape.isRequired,
    }),
  ),
  accessCodes: types.object,
}
Store.defaultProps = {
  atlases: [],
}

const mapStateToProps = (state, { i18n }) => {
  const allAtlases = [...Object.values(state.store)]
  const currentLanguage = i18n.language || 'es'
  return {
    atlases: allAtlases.filter(atlas =>
      (atlas.language || 'es').match(new RegExp(currentLanguage, 'i')),
    ),
    accessCodes: Object.values(state.accessCode).reduce(
      (all, { purchase: { atlas = { id: -1 } } = {} }) => {
        if (!all[atlas.id]) return { ...all, [atlas.id]: 1 }
        return {
          ...all,
          [atlas.id]: all[atlas.id] + 1,
        }
      },
      {},
    ),
  }
}

const mapDispatchToProps = ({ store: { getAtlases }, accessCode: { getAll } }) => ({
  getAtlases,
  getAccessCodes: getAll,
})

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(prefetch(['getAtlases', 'getAccessCodes'])(Store)),
)
