import React, { useState } from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import Button from '../../../components/button'
import { Input } from '../../../components/form'
import withRouter from '../../../config/router/withRouter'

export const AccessCode = ({
  id, user, editable, assign, unassign,
}) => {
  const [isEditing, setEditing] = useState(false)
  const [newUser, setNewUser] = useState('')

  const enableEdit = () => {
    setEditing(true)
    setNewUser('')
  }
  const cancel = () => {
    setEditing(false)
    setNewUser('')
  }
  const save = () => {
    assign({ email: newUser, id })
    cancel()
  }
  return (
    <tr>
      <td>{id}</td>
      {isEditing && editable ? (
        <td>
          <form onSubmit={save}>
            <Input
              width="50%"
              margin="auto"
              onChange={({ target: { value } }) => setNewUser(value)}
              value={newUser}
            />
          </form>
        </td>
      ) : (
        <td>{user.email || 'No asignado'}</td>
      )}
      <td>
        {editable
          && (isEditing ? (
            <>
              <Button primary onClick={save}>
                Guardar
              </Button>
              <Button onClick={cancel}>Cancelar</Button>
            </>
          ) : (
            <>
              <Button primary onClick={enableEdit}>
                {user.email ? 'Reasignar' : 'Asignar'}
              </Button>
              {user.email && <Button onClick={unassign}>Desasignar</Button>}
            </>
          ))}
      </td>
    </tr>
  )
}
AccessCode.propTypes = {
  id: t.string.isRequired,
  user: t.shape({
    email: t.string.isRequired,
  }),
  editable: t.bool,
  assign: t.func.isRequired,
  unassign: t.func.isRequired,
}
AccessCode.defaultProps = {
  user: {},
  editable: true,
}

const mapRouterToProps = ({
  match: {
    params: { purchaseId },
  },
}) => ({ purchaseId })

const mapDispatchToProps = ({ accessCode: { unassign, update } }, { purchaseId, id }) => ({
  assign: ({ email }) => update({ id, purchaseId, email }),
  unassign: () => unassign({ id }),
})

export default withRouter(mapRouterToProps)(
  connect(
    null,
    mapDispatchToProps,
  )(AccessCode),
)
