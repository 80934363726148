import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Modal,
} from '@material-ui/core'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import TitleHeader from '../../components/header'
import Container from '../../components/container'
import Button from '../../components/button'
import { ContainerIcon } from '../../components/icon'
import { LanguageCreate } from './Create'
import { LanguageUpdate } from './Update'
import { ReactComponent as IconEdit } from '../../assets/icons/edit.svg'
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalContent: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
})

const Languages = () => {
  const { t, i18n } = useTranslation('translations')
  const dispatch = useDispatch()
  const { languages } = useSelector(state => ({ languages: Object.values(state.languages) }))
  const classes = useStyles()
  const [creationModal, setCreationModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)

  useEffect(() => {
    dispatch.languages.getAll()
  }, [])
  const removeLanguage = data => {
    confirmAlert({
      title: t('deleteLanguage'),
      message: t('deleteLanguageMessage'),
      buttons: [
        {
          label: t('yes'),
          onClick: async () => {
            const status = await dispatch.languages.delete(data)
            if (!status) {
              return toast.error(t('deleteError'))
            }
            toast.success(t('deleteSuccess'))
          },
        },
        {
          label: t('no'),
          onClick: () => {},
        },
      ],
    })
  }
  return (
    <>
      <TitleHeader title={t('title')} />
      <Container>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('language')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {languages.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.language}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => setUpdateModal(row.id)}>
                      <ContainerIcon>
                        <IconEdit width={30} height={30} />
                      </ContainerIcon>
                    </Button>
                    {i18n.language !== row.language && (
                      <Button onClick={() => removeLanguage({ id: row.id })}>
                        <ContainerIcon>
                          <IconTrash width={30} height={30} />
                        </ContainerIcon>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.buttonContainer}>
          <Button primary onClick={() => setCreationModal(true)}>
            {t('add')}
          </Button>
        </div>
        <Modal open={creationModal} onClose={() => setCreationModal(false)}>
          <div className={classes.modalContent}>
            <LanguageCreate close={() => setCreationModal(false)} />
          </div>
        </Modal>

        <Modal open={updateModal} onClose={() => setUpdateModal(false)}>
          <div className={classes.modalContent}>
            {!!updateModal && (
              <LanguageUpdate close={() => setUpdateModal(false)} id={updateModal} />
            )}
          </div>
        </Modal>
      </Container>
    </>
  )
}

export default Languages
