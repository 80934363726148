import React from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import styled from '@emotion/styled'
import { isEmail } from 'validator'
import { toast } from 'react-toastify'
import withRouter from '../../../config/router/withRouter'
import prefetch from '../../../utils/prefetch'
import { Title, Content as BaseContent, SubTitle } from '../../../components/text'
import { Col, Row } from '../../../components/container'
import Button from '../../../components/button'

import Table from '../../../components/table'
import routes from '../../../config/router/routes'
import AccessCode from './AccessCode'
import { FileInput } from '../../../components/form'
import { createExcelFromMatrix, downloadExcel, getDataFromExcel } from '../../../utils/excel'

const Content = styled(BaseContent)`
  margin: 3px;
`

export const Purchase = ({
  atlas, purchase, goAtlas, goBack, unassignCode, assignCode,
}) => {
  const assignWithExcel = async file => {
    const [first, ...data] = await getDataFromExcel(file)
    const [code, ...codes] = purchase.accessCodes
    if (
      code.id === first.codigo
      && code.user
      && code.user.email === first['email asignado']
      && isEqual(codes.map(c => c.id), data.map(d => d.codigo))
    ) {
      toast.success('Excel importado correctamente')
      const formatedData = data.map(d => ({
        id: d.codigo,
        email: (d['email asignado'] || ' ').trim(),
      }))
      const assignedCodes = codes.reduce((assigned, c) => ({
        ...assigned,
        [c.id]: c.user ? c.user.email : false,
      }))
      formatedData.filter(assign => !assign.email && assignedCodes[assign.id]).forEach(unassignCode)
      formatedData.filter(assign => assign.email && isEmail(assign.email)).forEach(assignCode)
    } else {
      toast.error('Error al importar excel. Asegurate de solo cambiar los emails')
    }
  }
  const unassignAll = () => {
    if (window.confirm('Realmente desea desasignar todos los códigos?')) {
      purchase.accessCodes
        .slice(1)
        .filter(code => code.userId)
        .forEach(unassignCode)
    }
  }
  const createExcelTemplate = () => {
    const { accessCodes } = purchase
    const data = accessCodes.reduce(
      (all, { id, user: { email = '' } = {} }) => [...all, [id, email]],
      [['codigo', 'email asignado']],
    )
    const workbook = createExcelFromMatrix(data)
    downloadExcel(workbook, 'codigos_de_acceso.xlsx')
  }

  return purchase.id ? (
    <Col fullWidth>
      <Row justifyContent="space-between" fullWidth>
        <Title>{atlas.title}</Title>
        <Row>
          <Button primary onClick={goAtlas}>
            Ver Atlas
          </Button>
          <Button onClick={goBack}>Volver</Button>
        </Row>
      </Row>
      <Content>
        <strong>ID compra: </strong>
        {purchase.id}
      </Content>
      <Content>
        <strong>Precio: </strong>
        {purchase.amount}
      </Content>
      <Content>
        <strong>Cantidad: </strong>
        {purchase.quantity}
      </Content>
      <Content>
        <strong>Fecha: </strong>
        {purchase.startDate}
      </Content>
      <Content>
        <strong>Medio de pago: </strong>
        {purchase.payment ? purchase.payment.method : '-'}
      </Content>
      <Row justifyContent="space-between" fullWidth>
        <SubTitle>Códigos de acceso</SubTitle>
        <Row>
          <Button onClick={createExcelTemplate} contained primary margin={5}>
            Descargar excel asignacion
          </Button>
          <FileInput
            label="Asignar con Excel"
            showInput={false}
            onChange={e => {
              assignWithExcel(e.target.files[0])
              e.target.value = ''
            }}
          />
          <Button onClick={unassignAll}>Desasignar todos</Button>
        </Row>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Usuario</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {purchase.accessCodes.map((accessCode, i) => (
            <AccessCode key={accessCode.id} {...accessCode} editable={!!i} />
          ))}
        </tbody>
      </Table>
    </Col>
  ) : (
    <Content>Selecciona una compra</Content>
  )
}
Purchase.propTypes = {
  atlas: t.shape({ title: t.string.isRequired }),
  purchase: t.shape({
    id: t.string.isRequired,
    amount: t.number.isRequired,
    quantity: t.number.isRequired,
    fecha: t.string.isRequired,
  }),
  goAtlas: t.func.isRequired,
  goBack: t.func.isRequired,
  unassignCode: t.func.isRequired,
  assignCode: t.func.isRequired,
}
Purchase.defaultProps = {
  atlas: {},
  purchase: {},
}

const mapRouterToProps = ({
  match: {
    params: { atlasId, purchaseId },
  },
  history: { push, go },
}) => ({
  atlasId,
  purchaseId,
  goAtlas: () => push(routes.atlas(atlasId)),
  goBack: () => go(-1),
})

const mapStateToProps = ({ atlas, purchase, accessCode }, { atlasId, purchaseId }) => ({
  atlas: atlas[atlasId],
  purchase: {
    ...purchase[purchaseId],
    accessCodes: Object.values(accessCode).filter(code => code.purchaseId === purchaseId),
  },
})

const mapDispatchToProps = ({ atlas, purchase, accessCode }, { atlasId, purchaseId }) => ({
  getAtlas: () => atlas.getAtlas({ id: atlasId }),
  getPurchase: () => purchase.get({ id: purchaseId }),
  unassignCode: ({ id }) => accessCode.unassign({ id }),
  assignCode: ({ id, email }) => accessCode.assign({ email, id, purchaseId }),
})

export default withRouter(mapRouterToProps)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(prefetch(['getAtlas', 'getPurchase'])(Purchase)),
)
