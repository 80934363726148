import React from 'react'
import t from 'prop-types'
import styled from '@emotion/styled'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
`
const ModalMain = styled.div`
  background: white;
  ${props => (props.width !== '' ? `width: ${props.width};` : 'width: 50vw;')}
  ${props => (props.height !== '' ? `height: ${props.height};` : 'height: 30vh;')}
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  & > div {
    margin: 30px;
  }
  ${({ height }) => (height ? `height: ${height};` : '')}
  max-width: 90vw;
`

const ModalClose = styled.span`
  cursor: pointer;
  background: ${props => props.theme.color.primary.default};
  color: ${props => props.theme.font.color.white};
  font-family: ${props => props.theme.font.family};
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: 43px;
  height: 43px;
  font-weight: 700;
`

const Modal = ({
  show, width, children, onClose, overlayClose = true, ...props
}) => {
  if (!show) return null
  return (
    <ModalOverlay onMouseDown={(...e) => overlayClose && onClose(...e)}>
      <ModalMain
        onClick={e => e.stopPropagation()}
        width={width}
        onMouseDown={e => e.stopPropagation()}
        {...props}
      >
        <ModalClose onClick={onClose}>X</ModalClose>
        {children}
      </ModalMain>
    </ModalOverlay>
  )
}
Modal.propTypes = {
  show: t.bool,
  width: t.string,
  onClose: t.func.isRequired,
  children: t.oneOfType([t.element, t.arrayOf(t.element)]).isRequired,
  overlayClose: t.bool,
}
Modal.defaultProps = {
  show: false,
  width: '',
  overlayClose: true,
}

export default Modal
