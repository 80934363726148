import Api from './instance'

export default (() => {
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000'
  const api = new Api(apiUrl)
  const fileApi = new Api(`${process.env.REACT_APP_API_URL}/apiconverter/api/v1/storage/store`)
  return {
    languages: {
      getAll: () => api.get('/translations'),
      create: (data, token = '') => api.withToken(token).noSnakePost('/translations', data),
      update: ({ id, ...data }, token = '') =>
        api.withToken(token).noSnakePut(`/translations/${id}`, data),
      remove: (id, token = '') => api.withToken(token).del(`/translations/${id}`),
    },
    atlas: {
      getAll: (token = '') => api.withToken(token).get('/atlas'),
      get: (id, token = '') => api.withToken(token).get(`/atlas/${id}`),
      delete: (id, token = '') => api.withToken(token).del(`/atlas/${id}`),
      create: (atlas, token = '') => api.withToken(token).post('/atlas', atlas),
      patch: (atlas, token = '') => api.withToken(token).patch(`/atlas/${atlas.id}`, atlas),
      duplicate: (id, language, token) =>
        api.withToken(token).post(`/atlas/${id}/clone`, { language }),
    },
    accessCode: {
      getAll: (token = '') => api.withToken(token).get('/access_codes'),
      create: (token = '', code) => api.withToken(token).post('/access_codes', code),
      update: (token = '', { id, ...code }) =>
        api.withToken(token).put(`/access_codes/${id}`, code),
      assign: (token = '', { id, ...code }) =>
        api.withToken(token).patch(`/access_codes/${id}/assign`, code),
    },
    store: {
      getAll: (token = '') => api.withToken(token).get('/store'),
      get: (id, token = '') => api.withToken(token).get(`/store/${id}`),
    },
    purchase: {
      getAll: (token = '') => api.withToken(token).get('/purchases'),
      get: (id, token = '') => api.withToken(token).get(`/purchases/${id}`),
      create: (purchase, token = '') => api.withToken(token).post('/purchases', purchase),
    },
    atlasCategory: {
      getAll: (token = '') => api.withToken(token).get('/atlas_categories'),
      get: (id, token = '') => api.withToken(token).get(`/atlas_categories/${id}`),
      create: (data, token = '') =>
        api.withToken(token).post('/atlas_categories', { atlas_category: data }),
      delete: (id, token = '') => api.withToken(token).del(`/atlas_categories/${id}`),
    },
    anatomicMap: {
      getAll: (token = '') => api.withToken(token).get('/anatomic_maps'),
      get: (id, token = '') => api.withToken(token).get(`/anatomic_maps/${id}`),
      create: (map, token = '') => api.withToken(token).post('/anatomic_maps', map),
      patch: (id, map, token = '') => api.withToken(token).patch(`/anatomic_maps/${id}`, map),
      updateCategory: (id, data, token = '') =>
        api.withToken(token).patch(`/anatomic_maps/${id}/updatecategory`, data),
      delete: (id, token = '') => api.withToken(token).del(`/anatomic_maps/${id}`),
      gigaPan: (url, token) =>
        api.withToken(token).post('/anatomic_maps/process_aws_batch_task', { url }),
    },
    user: {
      get: (token = '', email) => api.withToken(token).get(`/users/${email}`),
      getAll: token => api.withToken(token).get('/users'),
      patch: (token, id, data) => api.withToken(token).patch(`/users/${id}/edit_role`, data),
    },
    auth: {
      login: data => api.post('/login', data),
      register: data => api.post('/users', { user: data }),
      loginWithCookies: () => api.post('/edx', {}, { withCredentials: true }),
    },
    bugReport: {
      report: ({ from, subject, bug }) =>
        api.post('/bugreports', {
          bugreport: { from, subject, bug },
        }),
    },

    upload: (file, cb = () => {}) => fileApi.uploadFile(file, cb),
    /* API CONFIG */
    withToken(token) {
      return api.withToken(token)
    },
  }
})()
