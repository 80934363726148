import React from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import routes from '../../../../config/router/routes'
import withRouter from '../../../../config/router/withRouter'
import { Select } from '../../../../components/form'

const OptionsMenu = ({
  atlasId, path, push, purchases,
}) => (
  <Select
    value={path}
    onChange={({ path: elementPath }) => push(elementPath)}
    options={purchases.map(({ id, startDate, endDate }) => ({
      key: id,
      text: `${startDate} --> ${endDate}`,
      path: routes.atlasPurchase(atlasId, id),
    }))}
  />
)

OptionsMenu.propTypes = {
  atlasId: t.string.isRequired,
  path: t.string.isRequired,
  push: t.func.isRequired,
  purchases: t.arrayOf(
    t.shape({
      id: t.string.isRequired,
      startDate: t.string.isRequired,
      endDate: t.string.isRequired,
    }),
  ).isRequired,
}

const mapRouterToProps = ({
  history: { push },
  location: { pathname },
  match: {
    params: { atlasId },
  },
}) => ({
  push,
  path: pathname,
  atlasId,
})

const mapStateToProps = ({ purchase }, { atlasId }) => ({
  purchases: Object.values(purchase).filter(
    ({ atlasId: purchaseAtlas }) => purchaseAtlas === atlasId,
  ),
})

export default withRouter(mapRouterToProps)(connect(mapStateToProps)(OptionsMenu))
