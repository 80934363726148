import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PaypalExpressBtn from 'react-paypal-express-checkout'
import { toast } from 'react-toastify'

const error = () => toast.error('error, try again')

const Main = ({ total, createPurchase, onCancel, onError }) => {
  const handleSuccess = payment => {
    createPurchase({ ...payment, method: 'paypal' })
    /*
     Congratulation, it came here means everything's fine!
     You can bind the "payment" object's value to your state or props or whatever here,
     please see below for sample returned data
    */
  }

  const handleCancel = data => {
    onCancel()
    /*
      User pressed "cancel" or close Paypal's popup!
      You can bind the "data" object's value to your state or props or whatever here,
      please see below for sample returned data
    */
  }

  const handleError = err => {
    /*
     The main Paypal's script cannot be loaded or somethings block the loading of that script!
     Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
     => sometimes it may take about 0.5 second for everything to get set,
       or for the button to appear
    */
    onError()
  }
  const client = {
    sandbox: process.env.REACT_APP_PAYPAL_SANDBOX_ID,
    production: process.env.REACT_APP_PAYPAL_PRODUCTION_ID,
  }
  return (
    <PaypalExpressBtn
      client={client}
      currency={process.env.NODE_ENV === 'development' ? 'USD' : 'CLP'}
      total={total || 1}
      env={process.env.NODE_ENV === 'development' ? 'sandbox' : 'production'}
      onError={handleError}
      onSuccess={handleSuccess}
      onCancel={handleCancel}
    />
  )
}
Main.propTypes = {
  total: PropTypes.number.isRequired,
  createPurchase: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const mapDispatchToProps = () => ({
  onError: () =>
    error({
      message: 'payment:generalError',
      title: 'payment:title',
    }),
  onCancel: () =>
    error({
      message: 'payment:cancel',
      title: 'payment:title',
    }),
})
export default connect(
  null,
  mapDispatchToProps,
)(Main)
