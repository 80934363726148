/* eslint-disable */
import React, { createRef, Component } from 'react'
import styled from '@emotion/styled'

import Renderer3D, { Renderer2D, Loading, InfoPanel, Controls } from 'renderer3d-model'

import Modal from 'react-modal'

import { ReactComponent as RotatePhoneIcon } from '../assets/icons/rotatePhone.svg'
import o9n from 'o9n'
import { toast } from 'react-toastify'
import { uploadFile } from '../services/aws'
import ProgressBar from './progressBar'
import { Title } from './text'
import { connect } from 'react-redux'

class Renderer3d extends Component {
  constructor(props) {
    super(props)
    this.render3d = createRef()
    this.loading = createRef()
    this.infoPanel = createRef()
    this.configGui = createRef()
    this.controls = createRef()
    this.fileInputRef = createRef()
    this.renderer = {}
    this.state = {
      isFullscreen: false,
      show: true,
      onFinishUpload: null,
      uploading: false,
      progress: 0,
    }
  }

  componentDidMount() {
    this.loadModel()
    ;['', 'webkit', 'moz', 'ms'].forEach(prefix =>
      document.addEventListener(prefix + 'fullscreenchange', this.handleIsFullscreen, false),
    )
    o9n.orientation.onchange = this.handleOrientationChange
    if (this.props.childRef) this.props.childRef(this)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      document.querySelectorAll('canvas').forEach(node => node.remove())
      this.loadModel()
    }
    if (!prevProps.childRef && this.props.childRef) {
      this.props.childRef(this)
    }
  }
  componentWillUnmount() {
    o9n.orientation.onchange = null
    ;['', 'webkit', 'moz', 'ms'].forEach(prefix =>
      document.removeEventListener(prefix + 'fullscreenchange', this.handleIsFullscreen, false),
    )
    if (this.props.childRef) this.props.childRef(undefined)
    this.renderer.scene.dispose()
    this.renderer.renderer.dispose()
  }

  handleIsFullscreen = () => {
    this.setState({ isFullscreen: document.fullscreen })
  }

  loadModel = () => {
    const { url: modelUrl, orbit, attachments, editable } = this.props

    const Renderer = modelUrl.match(/\.(jpg|jpeg|png)$/i) ? Renderer2D : Renderer3D
    this.renderer = new Renderer({
      modelUrl,
      loading: this.loading.current,
      infoPanel: this.infoPanel.current,
      container: this.render3d.current,
      configGui: this.configGui.current,
      editable,
      initial: {
        orbit,
        attachments,
      },
      callbacks: {
        addAttachment: () => {},
        removeAttachment: () => {},
        updateAttachmentData: () => {},
        updateAttachmentDefaultScreen: () => {},
        updateAttachmentPosition: () => {},
        updateDefaultOrbit: () => {},
        getCurrentState: () => {},
      },
    })
    this.renderer.animate()
  }

  handleOrientationChange = e => {
    const { isMobile } = this.props
    const controls = this.controls.current
    if (isMobile && o9n.orientation.type.match(/landscape/i) && !document.fullscreen) {
      controls.toggleFullscreen()
    } else if (isMobile && o9n.orientation.type.match(/portrait/i) && document.fullscreen) {
      controls.toggleFullscreen()
    }
  }
  handleUploadImage = onFinishUpload => {
    this.fileInputRef.current.click()
    this.setState({ onFinishUpload })
  }
  handleSelectFile = async e => {
    const [file] = e.target.files
    this.setState({ uploading: true })
    try {
      const { Key, ...a } = await uploadFile({ file, progress: this.handleProgressUpload })
      this.setState({ uploading: false })
      if (this.state.onFinishUpload) {
        this.props.gigapanProcess(`https://d2gg5obs453f89.cloudfront.net/${Key}`)
        this.state.onFinishUpload(`https://d2gg5obs453f89.cloudfront.net/${Key}`)
        this.setState({ onFinishUpload: null })
      }
      toast.success('Archivo subido correctamente. Estará disponible dentro de 1 hora.')
    } catch (e) {
      toast.error('Hubo un error en la subida. Intente de nuevo')
      this.setState({ uploading: false })
    }
  }
  handleProgressUpload = ({ loaded, total }) => {
    this.setState({ progress: (loaded * 100) / total })
  }

  render() {
    const { isMobile } = this.props
    const { isFullscreen, progress, uploading } = this.state
    return (
      <>
        <Container hide={isMobile && !isFullscreen}>
          <RendererContainerLayout>
            <div
              ref={this.render3d}
              style={{ width: '100%', height: '100%', position: 'relative' }}
            >
              <Loading ref={this.loading} />
              <InfoPanel ref={this.infoPanel} onUploadImage={this.handleUploadImage} />
              <Controls ref={this.controls} showFullscreen={!isMobile} />
            </div>
          </RendererContainerLayout>
        </Container>
        <Container hide={!isMobile}>
          <RendererContainerLayout background="black" small>
            <RotatePhoneIcon fill="white" width="10em" />
          </RendererContainerLayout>
        </Container>
        <input
          type="file"
          hidden
          ref={this.fileInputRef}
          onChange={this.handleSelectFile}
          multiple={false}
          accept=".svs"
        />
        <Modal
          isOpen={uploading}
          style={{
            overlay: {
              zIndex: 10000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            content: {
              top: null,
              left: null,
              right: null,
              bottom: null,
              width: '50vw',
            },
          }}
        >
          <Title>{progress > 0 ? 'Subiendo archivo' : 'Preparando archivo'}</Title>
          <ProgressBar label percentage={progress} />
        </Modal>
      </>
    )
  }
}

const Container = styled.div`
  width: 100%;
  ${({ hide }) => (hide ? 'display: none;' : '')}
  height: 100%;
`
const RendererContainerLayout = styled.div`
  height: ${({ small }) => (small ? '400' : '650')}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  ${({ background }) => (background ? `background: ${background};` : '')}
`
const ContainerIcon = styled.div`
  svg {
    fill: ${({ color }) => (color ? color : 'black')};
  }
`

const mapDispatchToProps = ({ anatomicMap }) => ({
  gigapanProcess: url => anatomicMap.gigapanProcess({ url }),
})

export default connect(
  null,
  mapDispatchToProps,
)(Renderer3d)
