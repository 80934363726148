import React, { Component } from 'react'
import types from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import RichTextEditor from 'react-rte'
import { Draggable } from 'react-beautiful-dnd'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { withTranslation } from 'react-i18next'
import { SubTitle } from '../../components/text'
import { Input } from '../../components/form'
import { ContainerIcon } from '../../components/icon'
import BaseModal from '../../components/modal'

import { ConnectedModelEditor as ModelEditor } from './ModelEditor'

import { ReactComponent as IconDescription } from '../../assets/icons/description.svg'
import { ReactComponent as IconImage } from '../../assets/icons/image.svg'
import { ReactComponent as IconRadio } from '../../assets/icons/radio.svg'
import { ReactComponent as IconVideo } from '../../assets/icons/video.svg'
import { ReactComponent as IconModel3d } from '../../assets/icons/3d.svg'
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg'
import { ReactComponent as IconDrag } from '../../assets/icons/drag.svg'
import ImageEditor from './ImageEditor'
import RadioEditor from './RadioEditor'
import VideoEditor from './VideoEditor'

const Modal = styled(BaseModal)`
  overflow: auto;
`

const Form = styled.form`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 5px 0;
  & > div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  ${({ new: isNew }) =>
    isNew
      ? `
  border-style: solid;
    border-color: blueviolet;
    border-radius: 5px;
    margin: 0px;
  `
      : ''}
`

const Icon = styled(ContainerIcon)`
  svg {
    width: 30px;
    height: 30px;
    margin: 10px;
  }
`

class AnatomicMapForm extends Component {
  save = debounce(async () => {
    const { id } = this.state
    const { t } = this.props
    const valid = this.valid()
    if (valid.every(e => e)) {
      const { patch, create, onCreate } = this.props
      if (id) {
        const { anatomicCategoriesAttributes, richDescription, richBody, ...state } = this.state
        const map = await patch({
          ...state,
          description: richDescription.toString('html'),
          body: richBody.toString('html'),
        })
        this.setState({ ...map })
      } else {
        const { richDescription, richBody, ...state } = this.state
        const map = await create({
          ...state,
          description: richDescription.toString('html'),
          body: richBody.toString('html'),
        })
        if (!map) {
          toast.error(t('notification.anatomicMapAddError'))
          return
        }
        onCreate(map.id)
      }
    } else {
      const [name, body, description] = valid
      toast.error(
        `${t('notification.anatomicMapSaveError')} ${!name ? t('notification.name') : ''} ${
          !description ? t('notification.description') : ''
        } ${!body ? t('notification.body') : ''}`,
        { delay: 1000 },
      )
    }
  }, 1000)

  static propTypes = {
    patch: types.func.isRequired,
    create: types.func.isRequired,
    onCreate: types.func.isRequired,
    id: types.string,
    description: types.string,
    title: types.string,
    url: types.string,
    radio: types.string,
    img: types.string,
    video: types.string,
    dragId: types.string,
    new: types.bool,
    body: types.string,
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.id) {
      return {
        id: props.id ? props.id : state.id,
        description: props.description ? props.description : state.description,
        title: props.title ? props.title : state.title,
        url: props.url ? props.url : state.url,
        radio: props.radio ? props.radio : state.radio,
        img: props.img ? props.img : state.img,
        video: props.video ? props.video : state.video,
        body: props.body ? props.body : state.body,
      }
    }
    return null
  }

  static defaultProps = {
    title: '',
    description: '',
    body: '',
    id: null,
    url: null,
    radio: null,
    img: null,
    video: null,
    dragId: '',
    new: false,
  }

  state = {
    ...omit(this.props, [
      'dragId',
      't',
      'i18n',
      'create',
      'patch',
      'removeModel',
      'removeAnatomicMap',
    ]),
    modal: null,
    richDescription: this.props.description
      ? RichTextEditor.createValueFromString(this.props.description, 'html')
      : RichTextEditor.createEmptyValue(),

    richBody: this.props.body
      ? RichTextEditor.createValueFromString(this.props.body, 'html')
      : RichTextEditor.createEmptyValue(),
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    if (!isEqual(prevProps, props)) {
      this.setState({
        ...omit(props, [
          'dragId',
          't',
          'i18n',
          'create',
          'patch',
          'removeModel',
          'removeAnatomicMap',
        ]),
        richDescription: props.description
          ? RichTextEditor.createValueFromString(props.description, 'html')
          : RichTextEditor.createEmptyValue(),
        richBody: props.body
          ? RichTextEditor.createValueFromString(props.body, 'html')
          : RichTextEditor.createEmptyValue(),
      })
    }
  }

  componentWillUnmount() {
    this.save.flush()
  }

  update = params => {
    this.setState(params)
    this.save()
  }

  valid = () => {
    const { name, richDescription, richBody } = this.state
    return [
      name !== '',
      richDescription.getEditorState().getCurrentContent().getPlainText() !== '',
      richBody.getEditorState().getCurrentContent().getPlainText() !== '',
    ]
  }

  onWrite = key => ({ target: { value } }) => {
    this.setState({ [key]: value }, this.save)
  }

  handleModelChange = ({ url, attachments, orbit }) => {
    this.setState({ url, m3D: { attachments, orbit } }, this.save)
  }

  openModal = modal => this.setState({ modal })

  closeModal = () => {
    this.setState({ modal: undefined })
  }

  formRender = () => {
    const { name, description, url, img, radio, video, richBody, richDescription } = this.state
    const { new: isNew, t } = this.props
    const [plainDescription, plainBody] = [
      richDescription.getEditorState().getCurrentContent().getPlainText(),
      richBody.getEditorState().getCurrentContent().getPlainText(),
    ]
    return (
      <Form onSubmit={e => e.preventDefault()} new={isNew}>
        <div>
          {!isNew ? (
            <Icon>
              <IconDrag />
            </Icon>
          ) : (
            <Icon style={{ opacity: 0 }}>
              <IconDrag />
            </Icon>
          )}

          <Input type="text" value={name} onChange={this.onWrite('name')} />
        </div>
        <div>
          <SubTitle light>{t('add')}</SubTitle>
          <Icon
            active={plainDescription && plainBody}
            fill={plainDescription ? !plainBody && '#ecb82f' : plainBody && '#ecb82f'}
            onClick={() => this.openModal('description')}
          >
            <IconDescription />
          </Icon>
          <Icon active={url} onClick={() => this.openModal('model')}>
            <IconModel3d />
          </Icon>
          <Icon active={radio && radio.length} onClick={() => this.openModal('radio')}>
            <IconRadio />
          </Icon>
          <Icon active={video && video.length} onClick={() => this.openModal('video')}>
            <IconVideo />
          </Icon>
          <Icon active={img && img.length} onClick={() => this.openModal('img')}>
            <IconImage />
          </Icon>
          <Icon onClick={() => this.removeAnatomicMap()}>
            <IconTrash />
          </Icon>
        </div>
      </Form>
    )
  }

  removeAnatomicMap = () => {
    const { new: isNew, removeAnatomicMap } = this.props
    if (isNew) {
    } else if (window.confirm('Desea eliminar este mapa anatómico? Esta acción es irreversible')) {
      removeAnatomicMap()
    }
  }

  handleRemoveModel = () => {
    const { id } = this.state
    this.props.removeModel({ id })
    this.setState({ modal: null })
  }

  handleRemove = () => {}

  renderModals = () => {
    const { m3D, modal, richDescription, url, name, id, richBody, img, radio, video } = this.state
    const { t } = this.props
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'LINK_BUTTONS',
        'BLOCK_TYPE_DROPDOWN',
        'HISTORY_BUTTONS',
      ],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        { label: 'Monospace', style: 'CODE' },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
        { label: 'BQ', style: 'blockquote' },
      ],
    }
    return (
      <div>
        <Modal
          show={modal === 'description'}
          onClose={() => {
            this.closeModal()
            this.save()
          }}
          height="80vh"
        >
          <div style={{ justifyContent: 'center' }}>
            <Tabs style={{ width: '100%' }}>
              <TabList>
                <Tab>{t('cover')}</Tab>
                <Tab>{t('body')}</Tab>
              </TabList>
              <TabPanel>
                <RichTextEditor
                  value={richBody}
                  onChange={value => this.setState({ richBody: value })}
                  className="rich-text-editor"
                  editorClassName="rich-text-edit-content"
                  toolbarConfig={toolbarConfig}
                />
              </TabPanel>
              <TabPanel>
                <RichTextEditor
                  value={richDescription}
                  onChange={value => this.setState({ richDescription: value })}
                  className="rich-text-editor"
                  editorClassName="rich-text-edit-content"
                  toolbarConfig={toolbarConfig}
                />
              </TabPanel>
            </Tabs>
          </div>
        </Modal>
        <Modal
          show={modal === 'model'}
          onClose={({ saved = false } = {}) => {
            if (saved) return this.closeModal()
            confirmAlert({
              title: t('confirm.close'),
              message: t('confirm.closeMessage'),
              buttons: [
                {
                  label: t('confirm.yes'),
                  onClick: () => this.closeModal(),
                },
                {
                  label: t('confirm.no'),
                  onClick: () => {},
                },
              ],
            })
          }}
          height="80vh"
        >
          <div>
            <SubTitle>{`Visualizador ${name ? `de "${name}"` : ''}`}</SubTitle>
            <ModelEditor
              path={url}
              {...m3D}
              onChange={this.handleModelChange}
              removeModel={() => this.handleRemoveModel()}
              closeModal={this.closeModal}
            />
          </div>
        </Modal>
        <Modal width="70vw" show={modal === 'radio'} onClose={() => this.closeModal()}>
          <RadioEditor
            onChange={this.update}
            img={radio}
            updateImage={(radio = []) => {
              this.update({ radio })
              if (radio.length === 0) {
                this.update({ radio: '' })
                this.closeModal()
              }
            }}
          />
        </Modal>
        <Modal width="70vw" show={modal === 'video'} onClose={() => this.closeModal()}>
          <VideoEditor
            onChange={this.update}
            video={video}
            updateVideo={(video = []) => {
              this.update({ video })
              if (video.length === 0) {
                this.update({ video: '' })
                this.closeModal()
              }
            }}
          />
        </Modal>
        <Modal width="70vw" show={modal === 'img'} onClose={() => this.closeModal()}>
          <ImageEditor
            showThumbs
            onChange={this.update}
            img={img}
            updateImage={(img = []) => {
              this.update({ img })
              if (img.length === 0) {
                this.update({ img: '' })
                this.closeModal()
              }
            }}
          />
        </Modal>
      </div>
    )
  }

  render() {
    const { new: isNew, id, dragId } = this.props
    return (
      <>
        {!isNew ? (
          <Draggable draggableId={id} index={dragId}>
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {this.formRender()}
              </div>
            )}
          </Draggable>
        ) : (
          <>{this.formRender()}</>
        )}
        {this.renderModals()}
      </>
    )
  }
}

const mapDispatchToProps = (
  { anatomicMap: { create, patch, del } },
  { id: anatomicMapId, atlasCategoryId },
) => ({
  create,
  patch,
  removeModel: ({ id }) => patch({ id, m3d: null, url: null }),
  removeAnatomicMap: () => del({ id: anatomicMapId, atlasCategoryId }),
})

export const ConnectedAnatomicMapForm = withTranslation('anatomicMapForm')(
  connect(null, mapDispatchToProps)(AnatomicMapForm),
)

export default AnatomicMapForm
