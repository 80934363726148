import React from 'react'
import styled from '@emotion/styled'
import OptionsMenu from './OptionsMenu'
import Purchase from './Purchase'

const Container = styled.div`
  margin: 30px;
  @media (max-width: 768px) {
    margin: 0;
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Purchases = () => (
  <Container>
    <ContentContainer>
      <OptionsMenu />
      <Purchase />
    </ContentContainer>
  </Container>
)
export default Purchases
