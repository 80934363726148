import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import api from '../api'

export const addLanguage = (lang, translations) => {
  Object.entries(translations).forEach(([ns, val]) => {
    i18n.removeResourceBundle(lang, ns)
    i18n.addResourceBundle(lang, ns, val)
  })
}
export const reloadLanguages = async () => {
  const response = await api.languages.getAll()
  const resources = response
    .filter(lang => lang.value)
    .reduce((all, lang) => ({ ...all, [lang.language]: lang.value }), {})
  Object.keys(resources).forEach(key => {
    addLanguage(key, resources[key])
  })
  i18n.changeLanguage(i18n.language)
}
i18n
  .use(initReactI18next)
  .init({
    lng: localStorage.language,
    resources: {},
    debug: process.env.NODE_ENV === 'development',
    returnObjects: true,
  })
  .then(async () => {
    reloadLanguages()
  })

export default i18n
