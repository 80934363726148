import React, { useState, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Paper, makeStyles } from '@material-ui/core'
import { toast } from 'react-toastify'
import Button from '../../components/button'
import { Title, Content } from '../../components/text'
import { Input, FileInput } from '../../components/form'
import { flattenToRealTranslations, realToFlattenTranslations } from '../../config/i18n/conversion'

const useStyles = makeStyles({
  modalPaper: {
    pointerEvents: 'all',
    width: '35%',
    minHeight: '35%',
    padding: '5px 20px',
  },
  form: {
    padding: 15,
  },
})
export const LanguageCreate = ({ close }) => {
  const { t, i18n } = useTranslation('translations')
  const [lang, setLang] = useState('')
  const [file, setFile] = useState([])
  const [translates, setTranslates] = useState(null)
  const [validTrans, setValidTrans] = useState(false)

  const exampleLanguage = useSelector(state => {
    const stateLang =
      Object.values(state.languages).find(l => l.language === i18n.language) ||
      Object.values(state.languages)[0]
    return realToFlattenTranslations(stateLang.value)
  })
  const valid = useMemo(() => {
    const langValid = lang.trim() !== ''
    return langValid && validTrans
  }, [lang, validTrans])
  const dispatch = useDispatch()
  const handleFileChange = e => {
    setFile(e.target.files)
    const reader = new FileReader()
    reader.addEventListener('load', event => {
      const { result } = event.target
      const data = result
        .split('\n')
        .map(row => row.split(';'))
        .reduce((all, [key, , val]) => ({ ...all, [key]: val }), {})
      const trans = flattenToRealTranslations(data)
      setTranslates(trans)

      const exampleKeys = Object.keys(exampleLanguage)
      const translatesKeys = Object.entries(realToFlattenTranslations(trans))
        .filter(([, val]) => val)
        .map(([key]) => key)
      if (
        translatesKeys.some(key => !exampleKeys.includes(key)) ||
        exampleKeys.some(key => !translatesKeys.includes(key))
      ) {
        toast.error(t('missingKeys'))
        setValidTrans(false)
        return false
      }
      setValidTrans(true)
    })
    reader.readAsText(e.target.files[0])
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const response = await dispatch.languages.create({
      language: lang.trim(),
      value: translates,
    })
    if (!response) return toast.error(t('form.createError'))
    close()
  }
  const handleDownloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,${Object.entries(exampleLanguage)
      .map(([key, val]) => `${key};${val}`)
      .join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'example.csv')
    document.body.appendChild(link)

    link.click()
  }
  const classes = useStyles()
  return (
    <Paper className={classes.modalPaper}>
      <Title>{t('form.title')}</Title>
      <Content>{t('form.instructions')}</Content>
      <Button primary onClick={handleDownloadCSV}>
        {t('downloadExample')}
      </Button>
      <form onSubmit={handleSubmit} className={classes.form}>
        <label>
          {t('form.langLabel')}
          <Input
            value={lang}
            onChange={e => setLang(e.target.value)}
            placeholder={t('form.langLabel')}
          />
        </label>
        <label>
          {t('form.fileLabel')}
          <FileInput
            label={t('form.fileButtonLabel')}
            file={file}
            onChange={handleFileChange}
            accept=".csv"
          />
        </label>
        <Button primary disabled={!valid}>
          {t('form.create')}
        </Button>
      </form>
    </Paper>
  )
}
