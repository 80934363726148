import React from 'react'
import t from 'prop-types'
import { Row, Container } from './container'
import { SubTitle } from './text'

const TitleHeader = ({ title, RightComponent, onTitleClick, style = {} }) => (
  <Container colored primary style={style}>
    <Row fullWidth justifyContent="space-between" padding="5px 0">
      <SubTitle white noMargin onClick={onTitleClick} clickable={!!onTitleClick}>
        {title}
      </SubTitle>
      {RightComponent && typeof RightComponent === 'function' ? <RightComponent /> : RightComponent}
    </Row>
  </Container>
)
TitleHeader.propTypes = {
  title: t.string.isRequired,
  RightComponent: t.func,
  onTitleClick: t.func,
}
TitleHeader.defaultProps = {
  RightComponent: null,
  onTitleClick: null,
}

export default TitleHeader
