import { toast } from 'react-toastify'
import api from '../../api/index'
import { cleanStore } from '..'

const model = {
  state: {},
  reducers: {
    set(_, payload) {
      return payload
    },
  },
  effects: dispatch => ({
    async logout() {
      dispatch({ type: 'RESET' })
      cleanStore()
    },
    async login({ email, password }) {
      try {
        const user = await api.auth.login({ email, password })
        dispatch.auth.set({
          ...user.data,
          token: user.token,
          role: user.role.tag,
        })
        return true
      } catch (e) {
        toast.error('Credenciales incorrectas')
        return false
      }
    },
    async loginWithCookies() {
      try {
        const user = await api.auth.loginWithCookies()
        dispatch.auth.set({
          ...user.data,
          token: user.token,
          role: user.role.tag,
        })
        return true
      } catch (e) {
        return false
      }
    },
    async signUp({ name, email, password }) {
      try {
        await api.auth.register({ name, email, password })
        return dispatch.auth.login({ email, password })
      } catch (e) {
        toast.error('Email actualmente en uso')
        return false
      }
    },
    async bugReport(
      { subject, bug },
      {
        auth: { email },
      },
    ) {
      try {
        await api.bugReport.report({ subject, bug, from: email })
        toast.success('Error reportado correctamente')
        return true
      } catch (e) {
        toast.error('Error al enviar reporte de error')
        return false
      }
    },
  }),
}

export default model
