import React from 'react'
import { connect } from 'react-redux'
import t from 'prop-types'
import { withTranslation } from 'react-i18next'
import withRouter from '../../config/router/withRouter'
import routes from '../../config/router/routes'
import { Container } from '../../components/container'
import TitleHeader from '../../components/header'
import { Content, Title } from '../../components/text'
import AtlasForm from '.'

const AtlasCreate = ({ create }) => (
  <>
    <TitleHeader title="Crear Atlas" />
    <Container>
      <Title>Formulario de creación de Atlas</Title>
      <Content>
        En el siguiente formulario uds debe asignar un nombre al atlas a crear. Los segmentos que
        este tenga. Una descripción del atlas en su completitud y una foto representativa del mismo.
      </Content>
      <AtlasForm handleSubmit={create} />
    </Container>
  </>
)
AtlasCreate.propTypes = {
  create: t.func.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = ({ atlas: { create } }, { goToEdit, i18n }) => ({
  create: async data => {
    const atlas = await create({ ...data, language: i18n.language || 'es' })
    if (atlas) {
      goToEdit(atlas.id)
    }
  },
})

const mapRouterToProps = ({ history: { push } }) => ({
  goToEdit: id => push(routes.atlasEditMaps(id)),
})

export const ConnectedAtlasCreate = withTranslation()(
  withRouter(mapRouterToProps)(connect(mapStateToProps, mapDispatchToProps)(AtlasCreate)),
)

export default AtlasCreate
