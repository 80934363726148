import { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import io from 'socket.io-client'
import { toast } from 'react-toastify'

const url = `${process.env.REACT_APP_SOCKET}`

const OneUserCheck = ({ email, logout }) => {
  const socket = useRef(null)
  useEffect(() => {
    if (email && process.env.NODE_ENV !== 'development') {
      socket.current = io.connect(url, { path: '/socket.io' })
      socket.current.on('connect', () => {
        socket.current.emit('room', email)
      })
      socket.current.on('message', msg => {
        logout()
        toast.error(
          'Tu cuenta ha sido accedida desde otra ubicación. Recuerda que solo puedes acceder desde una ubicación a la vez',
        )
      })
    }
    if (!email && socket.current) {
      socket.current.disconnect()
      socket.current = null
    }
  }, [email])
  return null
}

const mapStateToProps = ({ auth: { email } }) => ({
  email,
})
const mapDispatchToProps = ({ auth: { logout } }) => ({ logout })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OneUserCheck)
