import React from 'react'
import t from 'prop-types'
import { Menu, MenuElement } from '../../../components/menu'
import withRouter from '../../../config/router/withRouter'
import routes from '../../../config/router/routes'

export const AtlasCategoriesMenu = ({ atlasCategories, goAnatomicMap, selectedCategory }) => (
  <Menu>
    {atlasCategories.map(atlasCategory => (
      <MenuElement
        key={atlasCategory.id}
        dropdown={atlasCategory.anatomicMaps && atlasCategory.anatomicMaps.length > 0}
        selected={selectedCategory && selectedCategory.id === atlasCategory.id}
      >
        {atlasCategory.tag}
        {atlasCategory.anatomicMaps && atlasCategory.anatomicMaps.length > 0 ? (
          <Menu>
            {atlasCategory.anatomicMaps.map(anatomicMap => (
              <MenuElement onClick={() => goAnatomicMap(anatomicMap.id)}>
                {anatomicMap.name}
              </MenuElement>
            ))}
          </Menu>
        ) : null}
      </MenuElement>
    ))}
  </Menu>
)

AtlasCategoriesMenu.propTypes = {
  atlasCategories: t.arrayOf(
    t.shape({
      tag: t.string.isRequired,
    }),
  ),
  goAnatomicMap: t.func.isRequired,
  selectedCategory: t.shape({ id: t.number.isRequired }),
}
AtlasCategoriesMenu.defaultProps = {
  atlasCategories: [],
  selectedCategory: {},
}

const mapRouterToProps = ({
  match: {
    params: { atlasId: id },
  },
  history: { push },
}) => ({
  goAnatomicMap: mapId => push(routes.anatomicMap(id, mapId)),
})

export default withRouter(mapRouterToProps)(AtlasCategoriesMenu)
