import React from 'react'
import { connect } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import { Title } from '../../../components/text'
import withRouter from '../../../config/router/withRouter'
import DWV from '../../../components/dwv'

const Radiography = ({ radio }) => (
  <Carousel autoPlay={false} showThumbs={false} useKeyboardArrows>
    {radio.map(url => (
      <div style={{ width: '30vw' }}>
        <DWV url={url} />
      </div>
    ))}
  </Carousel>
)

const mapStateToProps = ({ anatomicMap }, { id }) => ({
  ...anatomicMap[id],
})
const mapRouterToProps = ({
  match: {
    params: { anatomicMapId: id },
  },
}) => ({ id })

export default withRouter(mapRouterToProps)(connect(mapStateToProps)(Radiography))
