import React from 'react'
import { useIsMobile } from '../../../utils/hooks'
import AtlasCategoriesMenuBrowser from './browser'
import AtlasCategoriesMenuMobile from './mobile'

export const AtlasCategoriesMenu = props => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return <AtlasCategoriesMenuMobile {...props} />
  }
  return <AtlasCategoriesMenuBrowser {...props} />
}

export default AtlasCategoriesMenu
