import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Carousel } from 'react-responsive-carousel'
import { Title } from '../../../components/text'
import withRouter from '../../../config/router/withRouter'

const VideoContainer = styled.div`
  max-width: 60vw;
  padding: 30px;
  margin: auto;
`
const Video = ({ video }) => (
  <Carousel autoPlay={false} showThumbs={false} useKeyboardArrows>
    {video.map(src => (
      <VideoContainer key={src}>
        <video src={src} controls style={{ width: '100%' }} />
      </VideoContainer>
    ))}
  </Carousel>
)

const mapStateToProps = ({ anatomicMap }, { id }) => ({
  ...anatomicMap[id],
})
const mapRouterToProps = ({
  match: {
    params: { anatomicMapId: id },
  },
}) => ({ id })

export default withRouter(mapRouterToProps)(connect(mapStateToProps)(Video))
