import React, { Fragment, Component } from 'react'
import types from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { withTranslation } from 'react-i18next'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import withRouter from '../../config/router/withRouter'
// import types from 'prop-types'
import { ContainerIcon as Icon, ContainerIcon } from '../../components/icon'
import { Container, Row } from '../../components/container'
import TitleHeader from '../../components/header'
import prefetch from '../../utils/prefetch'
import { Title, Content, SubTitle } from '../../components/text'
import Button from '../../components/button'
import numberIdGenerator from '../../utils/ids'
import routes from '../../config/router/routes'

import { ReactComponent as IconAtlas } from '../../assets/icons/atlas.svg'
import { ConnectedAnatomicMapForm as AnatomicMapForm } from './AnatomicMapForm'
import { ReactComponent as IconEdit } from '../../assets/icons/edit.svg'
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg'

const OptionsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  & > div {
    border-top: 2px solid ${({ theme }) => theme.color.default.light};
    border-bottom: 2px solid ${({ theme }) => theme.color.default.light};
    & > div {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      & > span {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: relative;
        & > span {
          position: absolute;
          left: -40px;
        }
      }
    }
    & > span {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      svg {
        margin: 10px;
      }
    }
  }
`
const DroppableContainer = styled.div`
  display: block !important;
`
const DelCatContainer = styled.div`
  display: flex;
`
const TopTitle = styled(Title)`
  display: flex;
  align-items: center;
`

class AnatomicsMapEdit extends Component {
  state = {
    loaded: false,
  }

  static propTypes = {
    title: types.string,
    atlasCategories: types.arrayOf(
      types.shape({
        id: types.string.isRequired,
        tag: types.string.isRequired,
      }),
    ),
    goPreview: types.func.isRequired,
  }

  static defaultProps = {
    title: '',
    atlasCategories: [],
  }

  id = numberIdGenerator()

  state = { newMaps: {} }

  componentDidUpdate({ loading: prevLoading, currentLng: prevLng }) {
    const { loading, currentLng } = this.props
    const { loaded } = this.state
    if (prevLoading && !loading && !loaded) {
      this.setState({ loaded: true })
    }
    if (prevLng !== currentLng) {
      this.setState({ loaded: false })
    }
  }

  addMap = id => {
    const { newMaps } = this.state
    const crtMapsId = newMaps[id] ? newMaps[id] : []
    this.setState({
      newMaps: { ...newMaps, [id]: [...crtMapsId, { myId: this.id.next().value }] },
    })
  }

  handleCreate = (cId, myId) => {
    const { newMaps } = this.state
    const index = newMaps[cId].findIndex(e => e.myId === myId)
    this.setState({
      newMaps: {
        ...newMaps,
        [cId]: [...newMaps[cId].slice(0, index), ...newMaps[cId].slice(index + 1)],
      },
    })
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result
    const { atlasCategories, updateAtlasCategory, updateDragorders } = this.props
    const atlasCategory = atlasCategories.find(category => category.id === source.droppableId)
    const anatomicMap = atlasCategory.anatomicMaps[source.index]
    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    if (destination.droppableId === source.droppableId) {
      const anatomicMaps = atlasCategory.anatomicMaps.map(({ id, dragorder, name }) => ({
        id,
        dragorder,
        name,
      }))
      const anatomicMapWithoutMovedMap = [
        ...anatomicMaps.slice(0, source.index),
        ...anatomicMaps.slice(source.index + 1),
      ]
      const anatomicMapWithMovedMapPositioned = [
        ...anatomicMapWithoutMovedMap.slice(0, destination.index),
        { id: anatomicMap.id, dragorder: anatomicMap.dragorder, name: anatomicMap.name },
        ...anatomicMapWithoutMovedMap.slice(destination.index),
      ]
      const newOrder = anatomicMapWithMovedMapPositioned.map((map, index) => ({
        ...map,
        newDragorder: index,
      }))
      const changes = newOrder.filter(({ dragorder, newDragorder }) => dragorder !== newDragorder)
      updateDragorders({ changes, atlasCategoryId: source.droppableId })
    } else {
      updateAtlasCategory({
        id: anatomicMap.id,
        atlasCategoryId: source.droppableId,
        newAtlasCategoryId: destination.droppableId,
      })
    }

    // TODO: persistencia
  }

  handleRemoveNew = id => {
    const { newMaps } = this.state
    const crtMapsId = newMaps[id] ? newMaps[id] : []
    this.setState({
      newMaps: { ...newMaps, [id]: [...crtMapsId, { myId: this.id.next().value }] },
    })
  }

  handleRemoveCategory = ({ id, anatomicMapsLength }) => {
    const { t } = this.props
    if (anatomicMapsLength > 0) {
      return () =>
        confirmAlert({
          title: t('confirm.cannotDeleteCategory'),
          message: t('confirm.cannotDeleteCategoryMessage'),
          buttons: [
            {
              label: t('confirm.ok'),
              onClick: () => {},
            },
          ],
        })
    }
    return () => {
      confirmAlert({
        title: t('confirm.deleteCategory'),
        message: t('confirm.deleteCategoryMessage'),
        buttons: [
          {
            label: t('confirm.yes'),
            onClick: async () => {
              const status = await this.props.deleteCategory(id)
              if (!status) {
                return toast.error(t('notification.deleteCategoryError'))
              }
              toast.success(t('notification.deleteCategorySuccess'))
            },
          },
          {
            label: t('confirm.no'),
            onClick: () => {},
          },
        ],
      })
    }
  }

  handleRemoveAtlas = () => {
    const { t } = this.props
    return confirmAlert({
      title: t('confirm.deleteAtlas'),
      message: t('confirm.deleteAtlasMessage'),
      buttons: [
        {
          label: t('confirm.yes'),
          onClick: async () => {
            const status = await this.props.deleteAtlas()
            if (!status) {
              return toast.error(t('notification.deleteAtlasError'))
            }
            this.props.goHome()
            toast.success(t('notification.deleteAtlasSuccess'))
          },
        },
        {
          label: t('confirm.no'),
          onClick: () => {},
        },
      ],
    })
  }

  handleDuplicateAtlas = lng => {
    const { t } = this.props
    confirmAlert({
      title: t('confirm.duplicate', { lng: lng.toUpperCase() }),
      buttons: [
        {
          label: t('confirm.yes'),
          onClick: async () => {
            const response = await this.props.duplicateAtlas(lng)
            if (!response) {
              return toast.error(t('notification.duplicateError'))
            }
            toast.success(t('notification.duplicateSuccess'))
            this.props.changeLanguage(lng)
          },
        },
        {
          label: t('confirm.no'),
          onClick: () => {},
        },
      ],
    })
  }

  render() {
    const { newMaps, loaded } = this.state
    const {
      id: atlasId,
      title,
      atlasCategories,
      goPreview,
      goEdit,
      siblings,
      languages,
      changeLanguage,
      t,
    } = this.props
    return (
      <Fragment>
        <TitleHeader title="Edición de Mapas Anatómicos" />
        <Container>
          <TopTitle>
            {t('anatomicMaps')}
            <Button onClick={goEdit}>
              <ContainerIcon>
                <IconEdit width={30} height={30} />
              </ContainerIcon>
            </Button>

            <Button onClick={this.handleRemoveAtlas}>
              <ContainerIcon>
                <IconTrash width={30} height={30} />
              </ContainerIcon>
            </Button>
          </TopTitle>
          <Content>{t('instructions')}</Content>
          <OptionsContainer>
            <Row justifyContent="space-between">
              <span>
                <Icon active>
                  <IconAtlas width={30} height={30} />
                </Icon>
                <Title light>{title}</Title>
              </span>
              <div>
                {languages.map(lng => {
                  const lngAtlas = siblings.find(a => a.language === lng)
                  return (
                    <Button
                      primary={lngAtlas && atlasId === lngAtlas.id}
                      key={lng}
                      onClick={() => {
                        if (lngAtlas) {
                          changeLanguage(lng)
                        } else {
                          this.handleDuplicateAtlas(lng)
                        }
                      }}
                    >
                      {lng.toUpperCase()}
                      {!lngAtlas && '+'}
                    </Button>
                  )
                })}
              </div>
            </Row>

            {loaded && (
              <DragDropContext onDragEnd={this.onDragEnd}>
                {atlasCategories.map(({ id, tag, anatomicMaps }) => (
                  <Container key={id}>
                    <div>
                      <span>
                        <Icon
                          onClick={this.handleRemoveCategory({
                            id,
                            anatomicMapsLength: anatomicMaps.length,
                          })}
                        >
                          <IconTrash width={30} height={30} />
                        </Icon>

                        <SubTitle>{tag}</SubTitle>
                      </span>
                      <DelCatContainer>
                        <Button primary onClick={() => this.addMap(id)}>
                          Añadir Mapa Anatómico
                        </Button>
                        {/* <SIcon onClick={() => console.log('patch')}>
                          <IconTrash />
                        </SIcon> */}
                      </DelCatContainer>
                    </div>
                    {newMaps[id] &&
                      newMaps[id].map(({ myId, mapId }) => (
                        <AnatomicMapForm
                          anatomicCategoriesAttributes={[{ atlasCategoryId: id }]}
                          id={mapId}
                          onCreate={mId => this.handleCreate(id, myId, mId)}
                          new
                          // onRemove={this.handleRemoveNew}
                        />
                      ))}

                    <Droppable droppableId={id}>
                      {provided => (
                        <DroppableContainer ref={provided.innerRef} {...provided.droppableProps}>
                          {!anatomicMaps.length && <div style={{ height: 50, width: '100%' }} />}
                          {anatomicMaps.map((anatomicMap, mapId) => (
                            <AnatomicMapForm dragId={mapId} {...anatomicMap} atlasCategoryId={id} />
                          ))}
                          {provided.placeholder}
                        </DroppableContainer>
                      )}
                    </Droppable>
                  </Container>
                ))}
              </DragDropContext>
            )}
          </OptionsContainer>
          <Button onClick={goPreview} contained primary>
            Previsualizar
          </Button>
        </Container>
      </Fragment>
    )
  }
}

AnatomicsMapEdit.propTypes = {}
AnatomicsMapEdit.defaultProps = {}

const mapStateToProps = (state, { atlasId: id, i18n, history }) => {
  const atlas = state.atlas[id]
  const siblings = Object.values(state.atlas).filter(a => a.sibling === atlas.sibling)
  const language = i18n.language || 'es'
  if (atlas.sibling && atlas.language && atlas.language !== language) {
    const languageAtlas = siblings.find(a => a.language.match(new RegExp(language, 'i')))
    if (languageAtlas && id !== languageAtlas.id) {
      history.push(routes.atlasEditMaps(languageAtlas.id))
    }
  }
  const {
    loading: {
      models: { atlasCategories: loadingCategories, anatomicMap: loadingMaps },
    },
  } = state
  return {
    ...atlas,
    atlasCategories: Object.values(state.atlasCategory)
      .filter(c => c.atlasId === id)
      .map(c => ({
        ...c,
        anatomicMaps: c.anatomicMaps
          .map(({ id: amId }) => state.anatomicMap[amId])
          .sort((a, b) => a.dragorder - b.dragorder),
      })),
    loading: loadingCategories || loadingMaps,
    siblings,
    languages: Object.keys(i18n.options.resources),
    changeLanguage: lng => {
      i18n.changeLanguage(lng)
      localStorage.setItem('language', lng)
    },
    currentLng: language,
  }
}

const mapDispatchToProps = ({ atlas, anatomicMap, atlasCategory }, { atlasId: id }) => ({
  getAtlas: () => atlas.getAtlas({ id }),
  getAnatomicMaps: () => anatomicMap.getAll(),
  updateAtlasCategory: ({ id: anatomicMapId, atlasCategoryId, newAtlasCategoryId }) =>
    anatomicMap.updateCategory({ id: anatomicMapId, atlasCategoryId, newAtlasCategoryId }),
  updateDragorders: ({ changes, atlasCategoryId }) =>
    anatomicMap.updateDragorders({ changes, atlasCategoryId }),
  deleteCategory: categoryId => atlasCategory.delete({ id: categoryId }),
  duplicateAtlas: language => atlas.duplicate({ id, language }),
  deleteAtlas: () => atlas.delete({ id }),
})

const mapRouterToProps = ({
  match: {
    params: { atlasId },
  },
  history: { push },
}) => ({
  atlasId,
  goPreview: () => push(routes.atlas(atlasId)),
  goEdit: () => push(routes.atlasEdit(atlasId)),
  goHome: () => push(routes.home),
})

export const ConnectedAnatomicsMapEdit = withTranslation('anatomicMapEdit')(
  withRouter(mapRouterToProps)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(prefetch(['getAtlas', 'getAnatomicMaps'])(AnatomicsMapEdit)),
  ),
)

export default AnatomicsMapEdit
