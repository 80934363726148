import api from '../../api/index'

const model = {
  state: {},
  reducers: {
    setAll(_, payload) {
      return payload.reduce(
        (all, a) => ({
          ...all,
          [a.id]: a,
        }),
        {},
      )
    },
    set(state, payload) {
      return {
        ...state,
        [payload.id]: payload,
      }
    },
  },
  effects: dispatch => ({
    async getAtlases(
      payload,
      {
        auth: { token },
      },
    ) {
      try {
        const atlases = await api.store.getAll(token)
        dispatch.store.setAll(atlases)
      } catch (e) {
        console.error(e)
      }
    },
    async getAtlas(
      { id },
      {
        auth: { token },
      },
    ) {
      try {
        const { store, atlasCategory } = dispatch
        const showAtlas = await api.store.get(id, token)
        store.set(showAtlas)
        await atlasCategory.getAll()
        await Promise.all(
          showAtlas.atlasCategories.map(category => atlasCategory.get({ id: category.id })),
        )
      } catch (e) {
        console.error(e)
      }
    },
  }),
}

export default model
