import React from 'react'
import { useSelector } from 'react-redux'
import Loader from 'react-loader-spinner'
import styled from '@emotion/styled'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`
export const FullLoader = () => {
  const loading = useSelector(state => state.loading.global)
  if (!loading) return null
  return (
    <Overlay>
      <Loader type="Oval" color="#009896" height={100} width={100} />
    </Overlay>
  )
}
