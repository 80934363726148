import { useState, useEffect } from 'react'

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    const onResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    document.addEventListener('resize', onResize)
    return () => {
      document.removeEventListener('resize', onResize)
    }
  })

  return dimensions
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth
      setIsMobile(width < 768)
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isMobile
}

export default { useDimensions, useIsMobile }
