import React from 'react'
import t from 'prop-types'
import styled from '@emotion/styled'

const ProgressBarContainer = styled.div`
  width: calc(100% - 60px);
  overflow: hidden;
  background: ${props => props.theme.color.default.light};
  border-radius: 20px;
  height: 40px;
  position: relative;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.font.color.white};
    font-family: ${props => props.theme.font.family};
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Progress = styled.div`
  width: ${({ percentage }) => (percentage ? `${percentage}%` : '0')};
  background: ${props => props.theme.color.primary.default};
  border-radius: 20px;
  height: 40px;
`

const ProgressBar = ({ percentage, label, ...props }) => (
  <div>
    <ProgressBarContainer {...props}>
      <Progress percentage={percentage} />
      <span>{label && `${Math.trunc(percentage * 100) / 100}%`}</span>
    </ProgressBarContainer>
  </div>
)
ProgressBar.propTypes = {
  percentage: t.number,
  label: t.bool,
}
ProgressBar.defaultProps = {
  percentage: 0,
  label: false,
}

export default ProgressBar
