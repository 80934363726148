import React, { Component, createRef } from 'react'
import t from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { SubTitle } from '../../components/text'
import { FileInput } from '../../components/form'
import ProgressBar from '../../components/progressBar'
import Button from '../../components/button'
import Renderer3d from '../../components/renderer3d'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const Container = styled.div`
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
`
const ImgContainer = styled.div`
  max-width: 50vw;
  max-height: 50vh;
  margin: auto;
`

const ActionContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
`

class ImageEditor extends Component {
  static propTypes = {
    onChange: t.func.isRequired,
    uploadModel: t.func.isRequired,
    img: t.string.isRequired,
    closeModal: t.func.isRequired,
  }

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    step: this.props.img && this.props.img.length ? 2 : 0,
    loaded: [],
    total: [],
    selected: 0,
    files: [],
    add: false,
    ...this.props,
  }

  onProgress = ({ loaded, total, i }) => {
    this.setState(state => ({
      total: [...state.total.slice(0, i), total, ...state.total.slice(i + 1)],
      loaded: [...state.loaded.slice(0, i), loaded, ...state.loaded.slice(i + 1)],
    }))
  }

  nextStep = () => {
    const { step } = this.state
    this.goToStep(step + 1)
  }

  goToStep = step => {
    this.setState({ step })
  }

  onFileChange = async ({ target: { files } }) => {
    const { img: currentImages } = this.state
    const { uploadModel, onChange } = this.props
    this.goToStep(1)
    this.setState({
      total: Array.from(files).map(() => 1),
      loaded: Array.from(files).map(() => 0),
    })
    const img = await Promise.all(
      Array.from(files).map((file, i) => uploadModel({
        file,
        cb: ({ loaded, total }) => {
          this.onProgress({ loaded, total, i })
        },
      })),
    )
    this.setState({
      img: [...(currentImages || []), ...img],
      add: false,
      files: [],
    })
    onChange({ img: [...(currentImages || []), ...img] })
    this.goToStep(2)
  }

  onRemove = () => {
    const { updateImage } = this.props
    const { img, selected } = this.state
    const newImages = [...img.slice(0, selected), ...img.slice(selected + 1)]
    this.setState({
      img: newImages,
      selected: selected >= newImages.length ? selected - 1 : selected,
    })
    updateImage(newImages)
  }

  render() {
    const { files, step, img } = this.state
    if (step === 0) {
      return (
        <Container>
          <SubTitle primary>Imagen</SubTitle>
          <FileInput
            file={files}
            multiple
            onChange={this.onFileChange}
            accept="image/x-png,image/gif,image/jpeg"
          />
          {img && img.length ? (
            <Button onClick={this.setState({ step: 2 })}>Cancelar</Button>
          ) : null}
        </Container>
      )
    }
    if (step === 1) {
      const { total, loaded } = this.state
      const percentage = (100 * loaded.reduce((sum, n) => sum + n)) / total.reduce((sum, n) => sum + n)
      return (
        <Container>
          <SubTitle primary>Imagen</SubTitle>
          <ProgressBar label percentage={percentage} />
        </Container>
      )
    }
    if (step === 2) {
      const { img, selected, add } = this.state
      const { closeModal, showThumbs = false } = this.props
      return (
        <Container>
          <SubTitle primary>Imagen</SubTitle>
          <Carousel
            autoPlay={false}
            showThumbs={showThumbs}
            onChange={e => this.setState({ selected: e })}
            selectedItem={selected}
            useKeyboardArrows
          >
            {img.map(src => (
              <ImgContainer key={src}>
                <img src={src} />
              </ImgContainer>
            ))}
          </Carousel>
          {add && (
            <FileInput
              file={files}
              multiple
              onChange={this.onFileChange}
              accept="image/x-png,image/gif,image/jpeg"
            />
          )}
          <ActionContainer>
            <Button onClick={() => this.onRemove()}>Eliminar</Button>
            <Button primary contained onClick={() => this.setState({ add: true })}>
              Agregar
            </Button>
          </ActionContainer>
        </Container>
      )
    }
    return null
  }
}

const mapDispatchToProps = ({ anatomicMap: { uploadModel } }) => ({ uploadModel })

export default connect(
  null,
  mapDispatchToProps,
)(ImageEditor)
