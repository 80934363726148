import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import types from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { connectedHome as Home } from './screens/Home'
import { connectedAtlas as Atlas } from './screens/Atlas'
import { ConnectedAtlasCreate as AtlasCreate } from './screens/AtlasForm.js/AtlasCreate'
import AtlasEdit from './screens/AtlasForm.js/AtlasEdit'
import { ConnectedAnatomicsMapEdit as AnatomicsMapEdit } from './screens/AnatomicsMapEdit'
import Users from './screens/Users'
import Languages from './screens/Languages'

import routes from './config/router/routes'
import { withNeedAuth, withNeedAnon } from './components/Authentication'
import { Container, Row } from './components/container'
import Text, { Small, Bold } from './components/text'
import Button from './components/button'
import { ReactComponent as LogoSvg } from './assets/icons/logo.svg'
import withRouter from './config/router/withRouter'
import SignIn from './screens/Auth/SignIn'
import SignUp from './screens/Auth/SignUp'
import { ReactComponent as BooksIcon } from './assets/icons/books.svg'
import { ReactComponent as StoreIcon } from './assets/icons/store.svg'
import { ReactComponent as UserIcon } from './assets/icons/users.svg'
import { ReactComponent as LanguageIcon } from './assets/icons/languages.svg'
import withAuthorization from './components/Authorization'
import NotFound from './screens/NotFound'
import Store from './screens/Store'
import StoreAtlas from './screens/Store/Atlas'
import OneUserCheck from './components/OneUser'
import BugReports from './components/BugReports'
import { Tracker } from './services/googleAnalitycs'
import { FullLoader } from './components/loader'

const Logo = styled(LogoSvg)`
  width: 250px;
`

const SpacedSelect = styled(Select)`
  margin-left: 10px;
`

const mapStateToProps = state => ({
  authorized: !!state.auth.token,
  user: state.auth.name,
  isAdmin: state.auth.role === 'mhAdmin',
})
const UserHeader = connect(({ auth }) => ({ user: auth ? auth.name : null }))(({ user }) => {
  if (!user) return null
  const { t } = useTranslation('navbar')
  return (
    <Container colored hideSm>
      <Row fullWidth justifyContent="space-between">
        <Small>
          {t('hi')} <Bold>{user}</Bold>!
        </Small>
      </Row>
    </Container>
  )
})
UserHeader.propTypes = {
  user: types.string,
}
UserHeader.defaultProps = {
  user: '',
}

const NavBar = withRouter(({ history, location: { pathname: path } }) => ({
  goHome: () => history.push(routes.home),
  goSignIn: () => history.push('/signin'),
  goAtlases: () => history.push(routes.home),
  goStore: () => history.push(routes.store),
  goUsers: () => history.push(routes.users),
  goLanguages: () => history.push(routes.languages),
  path,
}))(
  connect(
    mapStateToProps,
    ({ auth: { logout } }) => ({ logout }),
  )(
    ({
      goHome,
      logout,
      authorized,
      goSignIn,
      path,
      goAtlases,
      goStore,
      goUsers,
      goLanguages,
      isAdmin,
    }) => {
      const { i18n, t } = useTranslation('navbar')
      const currentLanguage = i18n.language || i18n.options.fallbackLng[0]
      const languages = useSelector(state =>
        Object.values(state.languages).map(lang => lang.language),
      )
      const dispatch = useDispatch()
      useEffect(() => {
        dispatch.languages.getAll()
      }, [])
      const changeLanguage = lng => {
        i18n.changeLanguage(lng)
        localStorage.setItem('language', lng)
      }
      return (
        <Container>
          <FullLoader />
          <Row fullWidth justifyContent="space-between" padding="5px 0">
            <a href="https://medicinehub.cloud/" target="_blank" rel="noopener noreferrer">
              <Logo />
            </a>
            <Row>
              {authorized && (
                <>
                  {isAdmin && (
                    <Button
                      primary={path.match(/^\/languages/)}
                      contained={false}
                      onClick={goLanguages}
                      padding="2px 5px"
                    >
                      <Row justifyContent="space-around">
                        <LanguageIcon width={30} />
                        <Text color="inherit" padding="0 10px" hideSm>
                          {t('languages')}
                        </Text>
                      </Row>
                    </Button>
                  )}
                  {isAdmin && (
                    <Button
                      primary={path.match(/^\/users/)}
                      contained={false}
                      onClick={goUsers}
                      padding="2px 5px"
                    >
                      <Row justifyContent="space-around">
                        <UserIcon width={30} />
                        <Text color="inherit" padding="0 10px" hideSm>
                          {t('users')}
                        </Text>
                      </Row>
                    </Button>
                  )}
                  <Button
                    primary={path.match(/^\/atlas/)}
                    contained={false}
                    onClick={goAtlases}
                    padding="2px 5px"
                  >
                    <Row justifyContent="space-around">
                      <BooksIcon width={30} />
                      <Text color="inherit" padding="0 10px" hideSm>
                        {t('atlases')}
                      </Text>
                    </Row>
                  </Button>
                  <Button
                    contained={false}
                    primary={path.match(/^\/store/)}
                    onClick={goStore}
                    padding="2px 5px"
                  >
                    <Row>
                      <StoreIcon width={30} />
                      <Text color="inherit" padding="0 10px" hideSm>
                        {t('store')}
                      </Text>
                    </Row>
                  </Button>
                  <Button
                    padding="2px 5px"
                    contained={false}
                    onClick={() => {
                      logout()
                      goSignIn()
                    }}
                  >
                    {t('signout')}
                  </Button>
                </>
              )}
              <SpacedSelect value={currentLanguage} onChange={e => changeLanguage(e.target.value)}>
                {languages.map(lang => (
                  <MenuItem key={lang} value={lang}>
                    {lang.toUpperCase()}
                  </MenuItem>
                ))}
              </SpacedSelect>
            </Row>
          </Row>
        </Container>
      )
    },
  ),
)

const withAtlasOwnerAuth = WrappedComponent =>
  withRouter(({ match: { params: { atlasId } } }) => ({
    atlasId,
  }))(
    withAuthorization({
      validate: (store, ownProps) => store.atlas[ownProps.atlasId].responsableId === store.auth.id,
      notFoundScreen: true,
    })(WrappedComponent),
  )

const withAtlasAccessAuth = Component =>
  withRouter(({ match: { params: { atlasId } } }) => ({
    atlasId,
  }))(
    withAuthorization({
      validate: (store, ownProps) => !!store.atlas[ownProps.atlasId],
      notFoundScreen: true,
    })(Component),
  )
const withAdminAuth = Component =>
  withAuthorization({
    validate: store => store.auth.role === 'mhAdmin',
    notFoundScreen: true,
  })(Component)

const authScreens = [
  { component: withNeedAuth(withAdminAuth(Languages)), path: routes.languages, exact: true },
  {
    component: withNeedAuth(withAdminAuth(Users)),
    path: routes.users,
    exact: true,
  },
  {
    component: withNeedAuth(withAtlasOwnerAuth(AnatomicsMapEdit)),
    path: routes.atlasEditMaps(),
    exact: true,
  },
  { component: withNeedAuth(Home), path: routes.home, exact: true },
  {
    component: withNeedAuth(AtlasCreate),
    path: routes.atlasCreate,
    exact: true,
  },
  {
    component: withNeedAuth(AtlasEdit),
    path: routes.atlasEdit(),
    exact: true,
  },
  {
    component: withNeedAuth(StoreAtlas),
    path: routes.storeAtlas(),
    exact: false,
  },
  { component: withNeedAuth(Store), path: routes.store, exact: true },
  { component: withNeedAuth(Atlas), path: routes.atlas(), exact: false },
  {
    component: withNeedAuth(withAtlasAccessAuth(Atlas)),
    path: routes.atlas(),
    exact: false,
  },
  { component: withNeedAnon(SignIn), path: routes.signin, exact: true },
  { component: withNeedAnon(SignUp), path: routes.signup, exact: true },
]

const App = () => (
  <>
    <Tracker />
    <UserHeader />
    <NavBar />
    <Switch>
      {authScreens.map(({ component: Component, path, exact }) => (
        <Route path={path} exact={exact} key={path} component={Component} />
      ))}
      <Route component={NotFound} />
    </Switch>
    <BugReports />
    <OneUserCheck />
  </>
)

export default App
