import React, { useState, useEffect } from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useTranslation } from 'react-i18next'
import Renderer3d from '../../../components/renderer3d'
import withRouter from '../../../config/router/withRouter'
import { useIsMobile } from '../../../utils/hooks'
import { Row, Div } from '../../../components/container'

const Divider = styled.div`
  height: 650px;
  width: 15px;
  background-color: #b5b3b3;
  margin: 0 5px;
  cursor: col-resize;
`

const Model = ({ url, m3D: { orbit, attachments }, description, body }) => {
  const [modelWidth, setModelWidth] = useState(window.innerWidth * 0.65)
  const [drag, setDrag] = useState(false)
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [modelWidth])
  const doDrag = e => {
    const { clientX } = e
    if (drag && window.innerWidth * 0.2 < clientX && window.innerWidth * 0.8 > clientX) {
      setModelWidth(clientX - 70)
    }
  }
  const disableDrag = () => setDrag(false)
  const isMobile = useIsMobile()
  const { t } = useTranslation('atlas')
  return (
    <Row full alignItems="flex-start" onMouseMove={doDrag} onMouseUp={disableDrag}>
      <Div width={`${modelWidth}px`}>
        <Renderer3d url={url} orbit={orbit} attachments={attachments} isMobile={isMobile} />
      </Div>
      <Divider
        onMouseDown={() => {
          setDrag(true)
        }}
      />
      <Div width={`${window.innerWidth * 0.9 - modelWidth}px`}>
        <Tabs style={{ width: '100%' }}>
          <TabList>
            <Tab>{t('cover')}</Tab>
            <Tab>{t('content')}</Tab>
          </TabList>
          <TabPanel>
            <p
              dangerouslySetInnerHTML={{ __html: body }}
              style={{
                paddingLeft: 25,
                paddingRight: 25,
                maxHeight: 650 - 32 - 50,
                overflow: 'auto',
              }}
            />
          </TabPanel>
          <TabPanel>
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              style={{
                paddingLeft: 25,
                paddingRight: 25,
                maxHeight: 650 - 32 - 50,
                overflow: 'auto',
              }}
            />
          </TabPanel>
        </Tabs>
      </Div>
    </Row>
  )
}
Model.propTypes = {
  url: t.string.isRequired,
  m3D: t.object.isRequired, // eslint-disable-line
}

const mapStateToProps = ({ anatomicMap }, { id }) => ({
  ...anatomicMap[id],
})
const mapRouterToProps = ({
  match: {
    params: { anatomicMapId: id },
  },
}) => ({ id })

export const ConnectedModel = withRouter(mapRouterToProps)(connect(mapStateToProps)(Model))

export default Model
