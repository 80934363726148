import React from 'react'
import t from 'prop-types'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Col } from '../../components/container'
import { SubTitle, Small as BaseSmall, Title } from '../../components/text'
import { Img as BaseImage } from '../../components/image'
import Button from '../../components/button'
import withRouter from '../../config/router/withRouter'
import routes from '../../config/router/routes'

const Container = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-item: center;
  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    & > * {
      margin: 10px;
    }
  }
`
const Small = styled(BaseSmall)`
  padding: 10px 0;
`
const Img = styled(BaseImage)`
  height: 260px;
  width: auto;
  @media (max-width: 768px) {
    width: 50%;
    height: 100%;
  }
`

const AtlasCard = ({ title, description, goAtlas, cover, biannualPrice, purchased = 0 }) => {
  const { t } = useTranslation('store')
  return (
    <Container>
      <Img
        alt={`Atlas: ${title}`}
        height="260px"
        src={
          cover && cover.url
            ? cover.url
            : 'https://sightlinemediaentertainment.com/wp-content/uploads/2015/09/placeholder-cover.jpg'
        }
      />
      <Col>
        <SubTitle>{title}</SubTitle>
        <Small>{description}</Small>
        <Col alignItems="center" full>
          <SubTitle primary>{t('from')}</SubTitle>
          <Title primary>${biannualPrice || '0'} CLP</Title>
          <Button primary onClick={goAtlas} contained center>
            {t('enter')}
          </Button>
          {purchased > 0 && <Small>{t('currentLicences', { amount: purchased })}</Small>}
        </Col>
      </Col>
    </Container>
  )
}
AtlasCard.propTypes = {
  title: t.string.isRequired,
  biannualPrice: t.number.isRequired,
  description: t.string.isRequired,
  goAtlas: t.func.isRequired,
  cover: t.string,
  purchased: t.number,
}
AtlasCard.defaultProps = {
  cover: '',
  purchased: 0,
}

const mapRouterToProps = ({ history }, { id }) => ({
  goAtlas: () => history.push(routes.storeAtlas(id)),
})
export const RouteredAtlasCard = withRouter(mapRouterToProps)(AtlasCard)
export default AtlasCard
