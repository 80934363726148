import React, { Component } from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Input } from '../../components/form'
import Button from '../../components/button'
import { Title } from '../../components/text'
import { AuthContainer } from '../../components/container'
import TitleHeader from '../../components/header'
import withRouter from '../../config/router/withRouter'
import routes from '../../config/router/routes'

class SignIn extends Component {
  static propTypes = {
    register: t.func.isRequired,
    redirectAfterSignup: t.func.isRequired,
    goSignin: t.func.isRequired,
  }

  state = {
    email: '',
    name: '',
    password: '',
    passwordR: '',
  }

  onWrite = key => ({ target: { value } }) => this.setState({ [key]: value })

  validate = () => {
    const {
      email, password, passwordR, name,
    } = this.state
    return email !== '' && password !== '' && password === passwordR && name !== ''
  }

  onSubmit = async e => {
    e.preventDefault()
    if (this.validate()) {
      const { register, redirectAfterSignup } = this.props
      if (await register(this.state)) {
        redirectAfterSignup()
      }
    } else {
      window.alert(
        'Hay errores en el formulario. Revisa que tienes todos los campos y que las contraseñas coinciden',
      )
    }
  }

  render() {
    const {
      email, password, passwordR, name,
    } = this.state
    const { goSignin, t } = this.props
    return (
      <>
        <TitleHeader title={t('signup')} />
        <AuthContainer>
          <Title>{t('signup')}</Title>
          <form onSubmit={this.onSubmit}>
            <Input
              type="email"
              placeholder="user@email.com"
              value={email}
              onChange={this.onWrite('email')}
            />
            <Input
              type="string"
              placeholder={t('form.name')}
              value={name}
              onChange={this.onWrite('name')}
            />
            <Input
              type="password"
              placeholder={t('form.password')}
              value={password}
              onChange={this.onWrite('password')}
            />
            <Input
              type="password"
              placeholder={t('form.repeatPassword')}
              value={passwordR}
              onChange={this.onWrite('passwordR')}
            />
            <Button primary contained>
              {t('signup')}
            </Button>
            <Button onClick={goSignin}>{t('alreadyHaveAccount')}</Button>
          </form>
        </AuthContainer>
      </>
    )
  }
}

const mapDispatchToProps = ({ auth }) => ({
  register: data => auth.signUp(data),
})
const mapRouterToProps = ({ history: { push }, location: { search } }) => {
  const params = new URLSearchParams(search)
  return {
    goSignin: () => push(routes.signin),
    redirectAfterSignup: () => push(params.has('redirect') ? params.get('redirect') : routes.home),
  }
}
export default withTranslation('auth')(withRouter(mapRouterToProps)(
  connect(
    null,
    mapDispatchToProps,
  )(SignIn),
))
