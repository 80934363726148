import React from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import routes from '../config/router/routes'
import withRouter from '../config/router/withRouter'

const withAuthentication = ({ needAuth = true, redirectTo = '' } = {}) => WrappedComponent => {
  const CheckAuth = ({
    signedId, currentPath, login, ...props
  }) => {
    if (needAuth && !signedId && redirectTo !== '') {
      login()
      const redirectPath = currentPath === '' ? redirectTo : `${redirectTo}?redirect=${currentPath}`
      return <Redirect to={redirectPath} />
    }
    if (!needAuth && signedId) {
      return <Redirect to={routes.home} />
    }
    return <WrappedComponent {...props} />
  }
  CheckAuth.propTypes = {
    signedId: t.bool.isRequired,
    currentPath: t.string,
  }
  CheckAuth.defaultProps = {
    currentPath: '',
  }
  const mapStateToProps = ({ auth: { token: signedId } }) => ({ signedId })
  const mapDispatchToProps = ({ auth: { loginWithCookies } }) => ({ login: loginWithCookies })
  const mapRouterToProps = ({ location: { pathname } }) => ({
    currentPath: pathname,
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(mapRouterToProps)(CheckAuth))
}

export default withAuthentication

export const withNeedAuth = withAuthentication({
  needAuth: true,
  redirectTo: routes.signin,
})

export const withNeedAnon = withAuthentication({
  needAuth: false,
})
