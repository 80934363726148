import { toast } from 'react-toastify'
import api from '../../api/index'

const model = {
  state: {},
  reducers: {
    setAll(_, payload) {
      return payload.reduce(
        (all, a) => ({
          ...all,
          [a.id]: a,
        }),
        {},
      )
    },
    set(state, payload) {
      return {
        ...state,
        [payload.id]: payload,
      }
    },
    remove(state, payload) {
      return Object.values(state).reduce((all, category) => {
        if (category.id === payload.id) return all
        return {
          ...all,
          [category.id]: category,
        }
      }, {})
    },
  },
  effects: dispatch => ({
    async getAll(payload, { auth: { token } }) {
      try {
        const atlasCategories = await api.atlasCategory.getAll(token)
        dispatch.atlasCategory.setAll(atlasCategories)
      } catch (e) {
        console.error(e)
      }
    },
    async get({ id }, { auth: { token } }) {
      try {
        const atlasCategory = await api.atlasCategory.get(id, token)
        dispatch.atlasCategory.set(atlasCategory)
      } catch (e) {
        console.error(e)
      }
    },
    async delete({ id }, { auth: { token } }) {
      try {
        await api.atlasCategory.delete(id, token)
        dispatch.atlasCategory.remove({ id })
        return true
      } catch (e) {
        return false
      }
    },
  }),
}

export default model
