import React from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import routes from '../../../../config/router/routes'

import { Menu as BaseMenu, MenuElement } from '../../../../components/menu'
import withRouter from '../../../../config/router/withRouter'

const Menu = styled(BaseMenu)`
  border-right: 2px solid ${({ theme }) => theme.font.color.primary};
  padding-right: 30px;
  margin-right: 50px;
  li {
    text-align: left;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    * {
      padding: 10px;
    }
    span {
      flex: 1;
    }
    div {
      flex: 0;
    }
  }
`
const OptionsMenu = ({
  purchases, atlasId, path, push,
}) => (
  <Menu transparent vertical>
    {purchases
      .map(({ id, startDate, endDate }) => ({
        key: id,
        text: `${startDate} --> ${endDate}`,
        path: routes.atlasPurchase(atlasId, id),
      }))
      .map(({ key, text, path: elementPath }) => (
        <MenuElement key={key} selected={path === elementPath} onClick={() => push(elementPath)}>
          <span>{text}</span>
        </MenuElement>
      ))}
  </Menu>
)

OptionsMenu.propTypes = {
  atlasId: t.string.isRequired,
  path: t.string.isRequired,
  push: t.func.isRequired,
  purchases: t.arrayOf(
    t.shape({
      id: t.string.isRequired,
      startDate: t.string.isRequired,
      endDate: t.string.isRequired,
    }),
  ).isRequired,
}
const mapRouterToProps = ({
  history: { push },
  location: { pathname },
  match: {
    params: { atlasId },
  },
}) => ({
  push,
  path: pathname,
  atlasId,
})

const mapStateToProps = ({ purchase }, { atlasId }) => ({
  purchases: Object.values(purchase).filter(
    ({ atlasId: purchaseAtlas }) => purchaseAtlas === atlasId,
  ),
})

export default withRouter(mapRouterToProps)(connect(mapStateToProps)(OptionsMenu))
