import React from 'react'

const useWhyDidYouUpdate = () => {
  // eslint-disable-next-line
  if (process.env.NODE_ENV !== 'production' && false) {
    const { whyDidYouUpdate } = require('why-did-you-update') // eslint-disable-line global-require
    whyDidYouUpdate(React)
  }
}

export default useWhyDidYouUpdate
