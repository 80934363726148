import React, { useState } from 'react'
import t from 'prop-types'
import { Menu, MenuElement } from '../../../components/menu'
import withRouter from '../../../config/router/withRouter'
import routes from '../../../config/router/routes'
import { Row } from '../../../components/container'
import Text from '../../../components/text'

export const AtlasCategoriesMenu = ({
  enabled,
  atlasCategories,
  goAnatomicMap,
  selectedCategory,
  hide: hideMenu,
}) => {
  if (!enabled) return null
  const [selected, setSelected] = useState(null)
  const select = id => {
    if (selected === id) setSelected(null)
    else setSelected(id)
  }
  return (
    <Menu vertical>
      {atlasCategories.map(atlasCategory => (
        <>
          <MenuElement
            key={atlasCategory.id}
            selected={selectedCategory && selectedCategory.id === atlasCategory.id}
            onClick={() => select(atlasCategory.id)}
          >
            <Row justifyContent="space-between" padding="0 2em">
              <Text white>{atlasCategory.tag}</Text>
              <Text white>{selected === atlasCategory.id ? '-' : '+'}</Text>
            </Row>
          </MenuElement>
          {selected === atlasCategory.id
          && atlasCategory.anatomicMaps
          && atlasCategory.anatomicMaps.length > 0 ? (
            <Menu vertical padding="0">
              {atlasCategory.anatomicMaps.map(anatomicMap => (
                <MenuElement
                  noBorder
                  onClick={() => {
                    goAnatomicMap(anatomicMap.id)
                    hideMenu()
                  }}
                >
                  {anatomicMap.name}
                </MenuElement>
              ))}
            </Menu>
            ) : null}
        </>
      ))}
    </Menu>
  )
}

AtlasCategoriesMenu.propTypes = {
  enabled: t.bool,
  atlasCategories: t.arrayOf(
    t.shape({
      tag: t.string.isRequired,
    }),
  ),
  goAnatomicMap: t.func.isRequired,
  hide: t.func.isRequired,
  selectedCategory: t.shape({ id: t.number.isRequired }),
}
AtlasCategoriesMenu.defaultProps = {
  atlasCategories: [],
  selectedCategory: {},
  enabled: false,
}

const mapRouterToProps = ({
  match: {
    params: { atlasId: id },
  },
  history: { push },
}) => ({
  goAnatomicMap: mapId => push(routes.anatomicMap(id, mapId)),
})

export default withRouter(mapRouterToProps)(AtlasCategoriesMenu)
