import api from '../../api/index'

const model = {
  state: {},
  reducers: {
    setAll(state, payload) {
      return payload.reduce(
        (all, a) => ({
          ...all,
          [a.id]: {
            ...all[a.id],
            ...a,
          },
        }),
        state,
      )
    },
    set(state, payload) {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload,
        },
      }
    },
  },
  effects: dispatch => ({
    async getAll(
      payload,
      {
        auth: { token },
      },
    ) {
      try {
        const purchases = await api.purchase.getAll(token)
        dispatch.purchase.setAll(purchases)
      } catch (e) {
        console.error(e)
      }
    },
    async get(
      { id },
      {
        auth: { token },
      },
    ) {
      try {
        const { purchase, accessCode } = dispatch
        const { accessCodes, ...showPurchase } = await api.purchase.get(id, token)
        purchase.set(showPurchase)
        if (accessCodes) accessCode.setAll(accessCodes)
      } catch (e) {
        console.error(e)
      }
    },
    async create(
      {
        type, amount, quantity, atlasId, payment,
      },
      {
        auth: { token, id: userId },
      },
    ) {
      try {
        const addMonts = (start, n) => new Date(start.setMonth(start.getMonth() + n))
        const purchase = await api.purchase.create(
          {
            purchase: {
              startDate: new Date(),
              endDate: type === 'biannual' ? addMonts(new Date(), 6) : addMonts(new Date(), 12),
              amount,
              quantity,
              buyerType: 'user',
              buyerId: userId,
              atlasId,
              responsableId: userId,
              payment,
            },
          },
          token,
        )
        dispatch.purchase.set(purchase)
        return purchase
      } catch (e) {
        console.error(e)
        return null
      }
    },
  }),
}

export default model
