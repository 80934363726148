import React from 'react'
import t from 'prop-types'
import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import ReactSelect from 'react-select'
import Button from './button'
import { Row, Div } from './container'
import Text from './text'

const BaseInput = styled.input`
  color: ${props => (props.color ? props.color : 'black')};
  font-family: ${props => props.theme.font.family};
  outline: none;
  border: none;
  ${({ flex }) => (flex ? `flex: ${flex};` : '')}
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
`

const TransparentInput = styled(BaseInput)`
  background: transparent;
`

const SearchIcon = styled(props => (
  <svg x="0px" y="0px" viewBox="0 0 56.966 56.966" {...props}>
    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
  </svg>
))`
  width: 17px;
  height: 17px;
  padding: 6px 10px;
  fill: ${props => (props.fill ? props.fill : 'black')};
`

const SearchInputContainer = styled.div`
  background: ${({ primary, theme }) => (primary ? theme.color.primary.light : theme.color.default.default)};
`

const Label = styled.label`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  input::placeholder {
    color: ${({ primary, theme }) => (primary ? theme.font.color.white : theme.font.color.default)};
    opacity: 0.6;
  }
`

export const SearchInput = withTheme(({ theme, primary, ...props }) => (
  <SearchInputContainer primary={primary}>
    <Label primary={primary}>
      <SearchIcon fill={primary ? theme.font.color.white : theme.font.color.default} />
      <TransparentInput
        {...props}
        color={primary ? theme.font.color.white : theme.font.color.default}
      />
    </Label>
  </SearchInputContainer>
))

export const Input = styled(BaseInput)`
  font-size: 18px;
  background: ${props => props.theme.color.default.light};
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  ::placeholder {
    color: ${props => props.theme.font.color.default};
    opacity: 0.6;
  }
`

const FileLabel = styled.label`
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

export const FileInput = ({
  label, onChange, file, showInput, ...props
}) => (
  <FileLabel>
    <input {...props} type="file" onChange={onChange} />
    <div>
      {showInput && (
        <Input as="div" flex={5}>
          {file.length ? file[0].name : null}
        </Input>
      )}
      <Button flex={1} as="div" contained primary onClick={() => {}}>
        {label}
      </Button>
    </div>
  </FileLabel>
)
FileInput.propTypes = {
  label: t.string,
  onChange: t.func,
  file: t.arrayOf(t.shape({ name: t.string.isRequired })),
  showInput: t.bool,
}
FileInput.defaultProps = {
  label: 'File',
  onChange: () => {},
  file: [],
  showInput: true,
}

const SelectOption = ({
  data: { text, Icon, path },
  innerProps,
  isFocused,
  selectProps: { value },
}) => {
  const isSelected = path === value
  const color = isSelected ? 'primary' : null

  return (
    <Row fullWidth {...innerProps} padding="0.5em 2em" bordered opacity={isFocused ? 0.8 : 1}>
      <Div padding="0 1em 0 0" color={color}>
        <Icon width={30} height={30} />
      </Div>
      <Text primary={color}>{text}</Text>
    </Row>
  )
}
SelectOption.propTypes = {
  data: t.shape({ text: t.string.isRequired, Icon: t.func.isRequired, path: t.string.isRequired })
    .isRequired,
  innerProps: t.shape({
    onClick: t.func.isRequired,
  }).isRequired,
  isFocused: t.bool.isRequired,
  selectProps: t.shape({ value: t.string.isRequired }).isRequired,
}

const StyledReactSelect = styled(ReactSelect)`
  width: 100%;
`

export const Select = ({
  value, onChange, options, Option = SelectOption, ...props
}) => (
  <StyledReactSelect
    value={value}
    onChange={onChange}
    options={options}
    {...props}
    components={{ Option }}
    isSearchable={false}
  />
)
Select.propTypes = {
  value: t.string.isRequired,
  onChange: t.func.isRequired,
  options: t.arrayOf(
    t.shape({
      text: t.string.isRequired,
      value: t.string.isRequired,
      Icon: t.element,
    }),
  ).isRequired,
}

export default BaseInput
