import React from 'react'
import { CenterContainer } from '../../components/container'
import TitleHeader from '../../components/header'
import { Title, SubTitle } from '../../components/text'

const NotFoundScreen = () => (
  <>
    <TitleHeader title="Error 404" />
    <CenterContainer>
      <Title>Error 404</Title>
      <SubTitle>Ups, la página buscada no existe, o no tienes los permisos para verla</SubTitle>
    </CenterContainer>
  </>
)

export default NotFoundScreen
