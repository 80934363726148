import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import t from 'prop-types'
import { Container } from '../../components/container'
import TitleHeader from '../../components/header'
import { Content, Title } from '../../components/text'
import AtlasForm from '.'
import BaseButton from '../../components/button'
import routes from '../../config/router/routes'

const Button = styled(BaseButton)`
  align-self: flex-start;
`
export const AtlasEdit = () => {
  const [loaded, setLoaded] = useState(false)
  const { atlasId } = useParams()
  const { push } = useHistory()
  const atlas = useSelector(state => state.atlas[atlasId])
  const {
    atlas: { update, getAtlas },
  } = useDispatch()
  useEffect(() => {
    getAtlas({ id: atlasId })
  }, [])
  useEffect(() => {
    if (!atlas) return
    setLoaded(true)
  }, [atlas])

  return (
    <>
      <TitleHeader title="Actualizar Atlas" />
      <Container>
        <Title>Formulario de edición de Atlas</Title>
        <Content>
          En el siguiente formulario uds debe asignar un nombre al atlas a actualizar. Los segmentos
          que este tenga. Una descripción del atlas en su completitud y una foto representativa del
          mismo.
        </Content>
        {loaded && <AtlasForm handleSubmit={update} atlas={atlas} update />}
        <Button onClick={() => push(routes.atlasEditMaps(atlasId))}>Volver al atlas</Button>
      </Container>
    </>
  )
}

export default AtlasEdit
