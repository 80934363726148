import React from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import {
  useTable, usePagination, useTableState, useFilters,
} from 'react-table'
import matchSorter from 'match-sorter'
import { useTranslation } from 'react-i18next'
import { Container, Row } from '../../components/container'
import Button from '../../components/button'
import TitleHeader from '../../components/header'
import prefetch from '../../utils/prefetch'
import { Select } from '../../components/form'

const ROLE_ENUM = { 1: 'Administrador', 2: 'Creador', 3: 'Usuario' }

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length
  const { t } = useTranslation('users')
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={t('search', { count })}
    />
  )
}
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

const TableStyler = styled.div`
  table,
  table th,
  table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  table {
    margin-top: 20px;
    margin-bottom: 40px;
    border-collapse: collapse;
    width: 100%;
  }
  table tbody tr:hover {
    background-color: #f5f5f5;
  }
  td,
  th {
    font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
  }
  th {
    font-weight: bold;
  }
  span {
    margin: 10px;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
  }
`

const UserTable = ({ users, patchUser }) => {
  const { t } = useTranslation('users')
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: fuzzyTextFilterFn,
    }),
    [],
  )
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  )
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: [{ pageIndex }],
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns: [
        { Header: 'id', accessor: 'id', show: false },
        { Header: t('table.name'), accessor: 'name', filter: 'fuzzyText' },
        { Header: t('table.email'), accessor: 'email' },
        { Header: t('table.role'), accessor: 'role' },
      ],
      data: users,
      defaultColumn,
      filterTypes,
    },
    useFilters,
    usePagination,
  )
  return (
    <TableStyler>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map(
            (row, i) => prepareRow(row) || (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                if (cell.column.id === 'role') {
                  return (
                    <td {...cell.getCellProps()}>
                      <select
                        value={cell.value}
                        onChange={e => patchUser({ id: cell.row.values.id, roleId: e.target.value })
                            }
                      >
                        {[1, 2, 3].map(i => (
                          <option key={i} value={i}>
                            {t(ROLE_ENUM[i])}
                          </option>
                        ))}
                      </select>
                    </td>
                  )
                }
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
            ),
          )}
        </tbody>
      </table>
      <div className="pagination">
        <Button primary contained onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>
        <span>{t('page', { actual: pageIndex + 1, total: pageOptions.length })}</span>
        <Button primary contained onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>
      </div>
    </TableStyler>
  )
}

const Users = ({ usersByRole = {}, patchUser }) => {
  const { t } = useTranslation('users')
  const {
    Administrador: admins = [],
    Creador: moderators = [],
    Usuario: students = [],
  } = usersByRole
  return (
    <>
      <TitleHeader title={t('users')} />
      <Container>
        <Tabs>
          <TabList>
            <Tab>{t('tabs.all')}</Tab>
            <Tab>{t('tabs.admins')}</Tab>
            <Tab>{t('tabs.creators')}</Tab>
            <Tab>{t('tabs.users')}</Tab>
          </TabList>
          <TabPanel>
            <UserTable users={[...admins, ...moderators, ...students]} patchUser={patchUser} />
          </TabPanel>
          <TabPanel>
            <UserTable users={admins} patchUser={patchUser} />
          </TabPanel>
          <TabPanel>
            <UserTable users={moderators} patchUser={patchUser} />
          </TabPanel>
          <TabPanel>
            <UserTable users={students} patchUser={patchUser} />
          </TabPanel>
        </Tabs>
      </Container>
    </>
  )
}
const mapStateToProps = state => ({
  usersByRole: Object.values(state.user).reduce(
    (all, user) => ({
      ...all,
      [ROLE_ENUM[user.role]]: [...all[ROLE_ENUM[user.role]], user],
    }),
    {
      Usuario: [],
      Administrador: [],
      Creador: [],
      undefined: [],
    },
  ),
})

const mapDispatchToProps = ({ user: { getUsers, patchUser } }) => ({
  getUsers,
  patchUser,
})

export default connect(mapStateToProps, mapDispatchToProps)(prefetch(['getUsers'])(Users))
