import React from 'react'
import ReactDOM from 'react-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import App from './App'

import withEmotionProvider from './config/emotion'
import './config/i18n/instance'
import withRouter from './config/router'
import withStore, { withPersistor } from './config/rematch'

import * as serviceWorker from './serviceWorker'

import pipe from './utils/pipe'

import useLogRocket from './services/logrocket'
import useSentry from './services/sentry'
import useWhyDidYouUpdate from './services/whyDidYouUpdate'
import withMaterialProvider from './config/material'

toast.configure()

const services = [useLogRocket, useSentry, useWhyDidYouUpdate]
services.forEach(service => service())

const providers = [
  withMaterialProvider(),
  withEmotionProvider,
  withRouter,
  withStore,
  withPersistor,
].reverse()

const RootApp = pipe(...providers)(App)
ReactDOM.render(<RootApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
