import styled from '@emotion/styled'

export const Container = styled.div`
  padding: 5px 40px;
  display: flex;
  flex-direction: column;
  background: ${({
    theme, primary, dark, light, colored,
  }) => {
    if (!colored) return 'transparent'
    let type = theme.color.default
    if (primary) {
      type = theme.color.primary
    }
    let shade = 'default'
    if (dark) shade = 'dark'
    if (light) shade = 'light'
    return type[shade]
  }};
  ${({ hideSm }) => (hideSm
    ? `
    @media (max-width: 768px) {
      display: none;
    }
  `
    : '')}
  @media (max-width: 768px) {
    padding: 5px;
  }
`

export const CenterContainer = styled(Container)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const Div = styled.div`
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  align-self: ${props => (props.alignSelf ? props.alignSelf : '')};
  flex-wrap: ${props => (props.wraped ? '' : 'no')}wrap;
  padding: ${props => (props.padding ? props.padding : '0')};
  ${({ bordered, theme }) => (bordered ? `border: 1px solid ${theme.color.default.default};` : '')}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
  ${({ fullHeight }) => (fullHeight ? 'height: 100%;' : '')}
  ${({ hideSm }) => (hideSm
    ? `
    @media (max-width: 768px) {
      display: none;
    }
  `
    : '')}
  ${({ color, theme }) => (color
    ? `color: ${theme.font.color[color]};
  fill: ${theme.font.color[color]};`
    : '')}
  ${({ opacity }) => (opacity >= 0 ? `opacity: ${opacity};` : '')}
  ${({ full }) => (full ? 'width: 100%; height: 100%;' : '')}
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ height }) => (height ? `height: ${height};` : '')}
`

export const Row = styled(Div)`
  flex-direction: row;
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
`
export const Col = styled(Div)`
  flex-direction: column;
`

export const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  input {
    margin: 20px;
  }
`

export default Container
