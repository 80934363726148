import React from 'react'
import { withRouter as wR } from 'react-router'

const withRouter = mapRouterToProps => Component => wR(({
  match, location, history, ...props
} = {}) => {
  const newProps = mapRouterToProps({ match, location, history }, props)
  return (
    <Component {...props} {...newProps} match={match} location={location} history={history} />
  )
})

export default withRouter
