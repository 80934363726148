import styled from '@emotion/styled'

const getTextColor = ({
  color, theme, light, white, primary,
}) => {
  if (color) return color
  let type = theme.font.color.default
  if (light) {
    type = theme.font.color.light
  }
  if (white) {
    type = theme.font.color.white
  }
  if (primary) {
    type = theme.font.color.primary
  }
  return type
}
const setCenter = ({ center }) => (center ? 'text-align: center;' : '')

const DefaultText = styled.span`
  font-family: ${props => props.theme.font.family};
  color: ${getTextColor};
  ${setCenter}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${({ hideSm }) => (hideSm
    ? `
    @media (max-width: 768px) {
      display: none;
    }
  `
    : '')}
`

export const Small = styled.small`
  font-family: ${props => props.theme.font.family};
  font-size: 12px;
  color: ${getTextColor};
  ${setCenter}
`

export const Bold = styled.strong`
  font-family: ${props => props.theme.font.family};
  font-weight: 700;
  color: ${getTextColor};
  ${setCenter}
`

export const SubTitle = styled.h2`
  font-family: ${props => props.theme.font.family};
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: ${getTextColor};
  ${props => (props.noMargin ? 'margin: 0;' : '')}
  ${props => (props.clickable ? 'cursor: pointer;' : '')}
  ${setCenter}
`

export const Title = styled.h1`
  font-family: ${props => props.theme.font.family};
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: ${getTextColor};
  ${props => (props.noMargin ? 'margin: 0' : '')};
  ${setCenter}
`

export const Content = styled.p`
  font-family: ${props => props.theme.font.family};
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: ${({ light = true, ...props }) => getTextColor({ light, ...props })};
  white-space: pre-line;
  ${setCenter}
`

export default DefaultText
