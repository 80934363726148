import React, { useState } from 'react'
import t from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { useTranslation, withTranslation } from 'react-i18next'
import { Container, Row } from '../../components/container'
import TitleHeader from '../../components/header'
import Button from '../../components/button'
import { RouteredAtlasCard as AtlasCard } from './AtlasCard'
import { SearchInput } from '../../components/form'
import prefetch from '../../utils/prefetch'
import routes from '../../config/router/routes'
import withRouter from '../../config/router/withRouter'

const SearchContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

const Home = ({ atlases, createAtlas, canCreate }) => {
  const { t } = useTranslation('home')
  const [search, setSearch] = useState('')
  return (
    <>
      <TitleHeader
        title={t('myAtlas')}
        RightComponent={
          <SearchContainer>
            <SearchInput
              primary
              placeholder={t('atlasTitle')}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            {canCreate && (
              <Button onClick={createAtlas} contained primary>
                {t('createAtlas')}
              </Button>
            )}
          </SearchContainer>
        }
      />
      <Container>
        <Row fullWidth wraped justifyContent="space-around">
          {atlases
            .filter(atlas => (atlas.title + atlas.description).match(new RegExp(search, 'i')))
            .map(atlas => (
              <AtlasCard {...atlas} key={atlas.id} />
            ))}
        </Row>
      </Container>
    </>
  )
}
Home.propTypes = {
  atlases: t.arrayOf(
    t.shape({
      title: t.string.isRequired,
      sectionsAmount: t.number.isRequired,
      description: t.shape.isRequired,
    }),
  ),
  createAtlas: t.func.isRequired,
  canCreate: t.func.isRequired,
}
Home.defaultProps = {
  atlases: [],
}

const mapStateToProps = (state, { i18n }) => {
  const allAtlases = [
    ...Object.values(state.atlas),
    {
      title: 'Proximamente',
      description: 'Nuevos cortes anatómicos',
      cover: { url: '/comingsoon-1a.jpg' },
      notReal: true,
    },
    {
      title: 'Proximamente',
      description: 'Nuevos cortes anatómicos',
      cover: { url: '/comingsoon-2a.jpg' },
      notReal: true,
    },
  ]
  const currentLanguage = i18n.language || 'es'
  return {
    atlases: allAtlases.filter(
      atlas => (atlas.language || 'es').match(new RegExp(currentLanguage, 'i')) || atlas.notReal,
    ),
    canCreate: ['mhAdmin', 'mhModerator'].includes(state.auth.role),
  }
}

const mapDispatchToProps = ({ atlas: { getAtlases } }) => ({
  getAtlases,
})

const mapRouterToProps = ({ history: { push } }) => ({
  createAtlas: () => push(routes.atlasCreate),
})

export const connectedHome = withTranslation()(
  withRouter(mapRouterToProps)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(prefetch(['getAtlases'])(Home)),
  ),
)

export default Home
