import { toast } from 'react-toastify'
import api from '../../api/index'

const model = {
  state: {},
  reducers: {
    setAll(_, payload) {
      return payload.reduce(
        (all, a) => ({
          ...all,
          [a.id]: a,
        }),
        {},
      )
    },
    set(state, payload) {
      return {
        ...state,
        [payload.id]: payload,
      }
    },
    setAndMerge(state, payload) {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload,
        },
      }
    },
  },
  effects: dispatch => ({
    async getAll(
      payload,
      {
        auth: { token },
      },
    ) {
      try {
        const anatomicMaps = await api.anatomicMap.getAll(token)
        dispatch.anatomicMap.setAll(anatomicMaps)
      } catch (e) {
        console.error(e)
      }
    },
    async get(
      { id },
      {
        auth: { token },
      },
    ) {
      try {
        const anatomicMap = await api.anatomicMap.get(id, token)
        dispatch.anatomicMap.set(anatomicMap)
      } catch (e) {
        console.error(e)
      }
    },
    async create(
      data,
      {
        auth: { token },
      },
    ) {
      try {
        const anatomicMap = await api.anatomicMap.create(data, token)
        dispatch.anatomicMap.set(anatomicMap)
        dispatch.atlasCategory.get({ id: data.anatomicCategoriesAttributes[0].atlasCategoryId })
        return anatomicMap
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async patch(
      { id, ...data },
      {
        auth: { token },
      },
    ) {
      try {
        const anatomicMap = await api.anatomicMap.patch(id, { id, ...data }, token)
        dispatch.anatomicMap.set(anatomicMap)
        toast.success('Mapa anatómico actualizado')
        return anatomicMap
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async gigapanProcess(
      { url },
      {
        auth: { token },
      },
    ) {
      try {
        return await api.anatomicMap.gigaPan(url, token)
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async updateCategory(
      { id, atlasCategoryId, newAtlasCategoryId },
      {
        auth: { token },
      },
    ) {
      try {
        const anatomicMap = await api.anatomicMap.updateCategory(
          id,
          { atlasCategoryId, newAtlasCategoryId },
          token,
        )
        dispatch.anatomicMap.set(anatomicMap)
        dispatch.atlasCategory.get({ id: atlasCategoryId })
        dispatch.atlasCategory.get({ id: newAtlasCategoryId })
        toast.success('Categoría cambiada con éxito')
        return anatomicMap
      } catch (e) {
        toast.error('Ha ocurrido un error al intentar cambiar de categoría')
        console.error(e)
        return null
      }
    },
    async updateDragorders(
      { changes, atlasCategoryId },
      {
        auth: { token },
      },
    ) {
      try {
        changes.map(({ id, newDragorder }) =>
          dispatch.anatomicMap.setAndMerge({ id, dragorder: newDragorder }),
        )
        await Promise.all(
          changes.map(({ id, newDragorder }) =>
            api.anatomicMap.patch(id, { anatomicMap: { dragorder: newDragorder } }, token),
          ),
        )
        toast.success('Posición actualizada con exito')
        dispatch.atlasCategory.get({ id: atlasCategoryId })
        return true
      } catch (e) {
        toast.error('Ha ocurrido un error al intentar actualizar el orden')
        console.error(e)
        return null
      }
    },
    async uploadModel({ file, cb }) {
      try {
        const {
          fileNames: [path],
        } = await api.upload(file, cb)
        return `https://d2gg5obs453f89.cloudfront.net/${path}`
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async del(
      { id, atlasCategoryId },
      {
        auth: { token },
        atlasCategory,
      },
    ) {
      try {
        const index = atlasCategory[atlasCategoryId].anatomicMaps.findIndex(map => map.id === id)
        const currentAtlasCategory = {
          ...atlasCategory[atlasCategoryId],
          anatomicMaps: [
            ...atlasCategory[atlasCategoryId].anatomicMaps.slice(0, index),
            ...atlasCategory[atlasCategoryId].anatomicMaps.slice(index + 1),
          ],
        }
        dispatch.atlasCategory.set(currentAtlasCategory)
        toast.success('Mapa anatómico eliminado correctamente')
        await api.anatomicMap.delete(id, token)
        dispatch.atlasCategory.get({ id: atlasCategoryId })
      } catch (e) {
        console.error(e)
      }
    },
  }),
}

export default model
