import React, { Component, createRef } from 'react'
import t from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { SubTitle, Content } from '../../components/text'
import { FileInput } from '../../components/form'
import ProgressBar from '../../components/progressBar'
import Button from '../../components/button'
import Renderer3d from '../../components/renderer3d'

const Container = styled.div`
  width: 100%;
  flex: 1;
  justify-content: flex-start !important;
  alint-items: stretch !important;
  & > * {
    width: 100%;
  }
`

class ModelEditor extends Component {
  model = createRef()

  static propTypes = {
    onChange: t.func.isRequired,
    uploadModel: t.func.isRequired,
    path: t.string.isRequired,
    closeModal: t.func.isRequired,
  }

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    step: this.props.path ? 2 : 0,
    progress: 0,
    ...this.props,
  }

  componentWillUnmount() {}

  save = async () => {
    // force re render to get model ref
    await new Promise(res => this.setState({}, res))
    try {
      const { path: url } = this.state
      const { attachments, orbit } = this.model.current.renderer.getCurrentState()
      const { onChange } = this.props
      await onChange({
        url,
        attachments: attachments.map(
          ({ title, content, screenPosition, position, type, imageUrl, ...attachment }) => {
            if (screenPosition) {
              return {
                data: {
                  content,
                  title,
                  screenPosition: {
                    position: screenPosition.position,
                    rotation: screenPosition.rotation,
                  },
                  imageUrl,
                  type,
                },
                position,
                ...attachment,
              }
            }
            return {
              data: {
                content,
                title,
                imageUrl,
                type,
              },
              position,
            }
          },
        ),
        orbit,
      })
      this.props.closeModal({ saved: true })
    } catch (e) {
      console.error(e)
      toast.error('Error al guardar contenido')
    }
  }

  onProgress = ({ loaded, total }) => {
    this.setState({ progress: (loaded / total) * 100 })
  }

  nextStep = () => {
    const { step } = this.state
    this.setState({
      step: step + 1,
    })
  }

  onFileChange = async ({
    target: {
      files: [file],
    },
  }) => {
    const { uploadModel, onChange } = this.props
    this.nextStep()
    const path = await uploadModel({ file, cb: this.onProgress })
    if (!path) {
      this.setState({ path: 0 })
      return
    }
    this.setState({ path })
    onChange({ url: path })
    this.nextStep()
  }

  onRemoveModel = () => {
    const { removeModel, closeModal } = this.props
    this.setState({ removed: true }, () => {
      removeModel()
      closeModal({ saved: true })
    })
  }

  render() {
    const { step, files } = this.state

    if (step === 0) {
      return (
        <Container>
          <SubTitle primary>Visualizador</SubTitle>
          <Content>
            Puedes subir una imágen (2D) o un zip con el modelo 3D (obj, mtl y jpeg)
          </Content>
          <FileInput file={files} onChange={this.onFileChange} />
        </Container>
      )
    }
    if (step === 1) {
      const { progress: percentage } = this.state
      return (
        <Container>
          <SubTitle primary>Visualizador</SubTitle>
          <ProgressBar label percentage={percentage} />
        </Container>
      )
    }
    if (step === 2) {
      const { path, orbit, attachments } = this.state

      return (
        <Container>
          <SubTitle primary>Visualizador</SubTitle>
          <div>
            <Renderer3d
              childRef={ref => {
                this.model.current = ref
              }}
              url={path}
              orbit={orbit}
              attachments={attachments}
              editable
            />
            <Button onClick={this.save} contained primary>
              Guardar y cerrar
            </Button>
            <Button onClick={() => this.onRemoveModel()}>Eliminar</Button>
          </div>
        </Container>
      )
    }
    return null
  }
}

const mapDispatchToProps = ({ anatomicMap: { uploadModel } }) => ({ uploadModel })

export const ConnectedModelEditor = connect(null, mapDispatchToProps)(ModelEditor)

export default ModelEditor
