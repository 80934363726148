import styled from '@emotion/styled'

const getButtonBackground = ({ theme, primary, dark, light, contained }) => {
  if (!contained) return 'transparent'
  let type = theme.color.default
  if (primary) {
    type = theme.color.primary
  }
  let shade = 'default'
  if (dark) shade = 'dark'
  if (light) shade = 'light'
  return type[shade]
}
const getButtonColor = ({ theme, primary, contained }) => {
  if (!contained) {
    return primary ? theme.color.primary.default : theme.color.default.default
  }
  if (primary) {
    return theme.font.color.white
  }
  return theme.font.color.default
}

const BaseButton = styled.button`
  border-radius: 20px;
  font-size: 20px;
  font-family: ${props => props.theme.font.family};
  background-color: ${props => getButtonBackground({ ...props, dark: false, light: false })};
  color: ${getButtonColor};
  fill: ${getButtonColor};
  padding: ${({ padding }) => padding || '5px 30px'};
  border: none;
  cursor: pointer;
  outline: none;
  transition-duration: 0.4s;
  ${({ flex }) => (flex ? `flex: ${flex};` : '')}
  &:hover {
    opacity: 0.7;
  }
  &:active {
    background: ${props =>
      getButtonBackground({
        ...props,
        dark: true,
        light: false,
        contained: true,
      })};
    color: ${props => getButtonColor({ ...props, primary: true })};
    fill: ${props => getButtonColor({ ...props, primary: true })};
  }
  &:disabled {
    background: ${props =>
      getButtonBackground({
        ...props,
        primary: false,
        light: true,
      })};
    color: ${props => getButtonColor({ ...props, light: true, primary: false })};
    fill: ${props => getButtonColor({ ...props, light: true, primary: false })};
    cursor: not-allowed;
  }
  ${({ margin }) => (margin ? `margin: ${margin}px;` : '')}
`

export default BaseButton
