import { toast } from 'react-toastify'
import api from '../../api/index'

const model = {
  state: {},
  reducers: {
    setAll(_, payload) {
      return payload.reduce((all, [id, name, email, role]) => {
        if (email.indexOf('deprecated') !== -1) return all
        return {
          ...all,
          [id]: {
            id,
            name,
            email,
            role,
          },
        }
      }, {})
    },
    set(state, payload) {
      return {
        ...state,
        [payload.id]: payload,
      }
    },
  },
  effects: dispatch => ({
    async getUsers(
      payload,
      {
        auth: { token },
      },
    ) {
      try {
        const atlases = await api.user.getAll(token)
        dispatch.user.setAll(atlases)
      } catch (e) {
        console.error(e)
      }
    },
    async patchUser(
      payload,
      {
        auth: { token },
      },
    ) {
      try {
        await api.user.patch(token, payload.id, { role_id: Number(payload.roleId) })
        dispatch.user.getUsers()
        toast.success('Usuario actualizado con exito')
      } catch (e) {
        toast.error('Ha ocurrido un error al actualizar el usuario')
        console.error(e)
      }
    },
  }),
}

export default model
