import styled from '@emotion/styled'

export const Table = styled.table`
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.font.family};
  & > thead {
    color: ${({ theme }) => theme.font.color.primary};
  }
  & > tbody {
    color: ${({ theme }) => theme.font.color.light};
  }

  border-collapse: collapse;
  & > thead > tr > th,
  & > tbody > tr > td {
    border-bottom: 1px solid ${({ theme }) => theme.font.color.light};
    padding: 12px 0;
  }
`

export default Table
