import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Title } from '../../../components/text'
import withRouter from '../../../config/router/withRouter'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const ImgContainer = styled.div`
  max-width: 50vw;
  margin: auto;
`
const Images = ({ img }) => (
  <Carousel autoPlay={false} showThumbs useKeyboardArrows>
    {img.map(src => (
      <ImgContainer key={src}>
        <img src={src} />
      </ImgContainer>
    ))}
  </Carousel>
)

const mapStateToProps = ({ anatomicMap }, { id }) => ({
  ...anatomicMap[id],
})
const mapRouterToProps = ({
  match: {
    params: { anatomicMapId: id },
  },
}) => ({ id })

export default withRouter(mapRouterToProps)(connect(mapStateToProps)(Images))
