import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'

import TitleHeader from './header'
import Input from './form'
import { ContainerIcon } from './icon'
import BaseButton from './button'

import { ReactComponent as IconBug } from '../assets/icons/bug.svg'

const FixedBottom = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const Icon = styled(ContainerIcon)`
  svg {
    width: 30px;
    height: 30px;
    margin: 10px;
  }
`

const QuestionContainer = styled.div`
  width: 300px;
  height: 500px;
  margin-left: auto;
  border-radius: 25px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  box-shadow: 0px 0px 8px 0px #009896d9;
  background-color: white;
`
const Button = styled(BaseButton)`
  border-radius: 50%;
  width: 67px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`
const Close = styled.span`
  font-size: 30px;
  font-weight: 800;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
`

const Form = styled.form`
  margin: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const BugReports = () => {
  const [visible, setVisible] = useState(false)
  const [reason, setReason] = useState('')
  const [content, setContent] = useState('')
  const dispatch = useDispatch()
  const handleSubmit = async e => {
    e.preventDefault()
    if (reason === '' || content === '') return
    const reported = await dispatch.auth.bugReport({ subject: reason, bug: content })
    if (!reported) return
    setReason('')
    setContent('')
    setVisible(false)
  }
  return (
    <FixedBottom>
      {visible && (
        <QuestionContainer>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <TitleHeader
              title="Reporte de errores"
              style={{
                borderTopLeftRadius: '21px',
                borderTopRightRadius: '21px',
              }}
            />
            <Form onSubmit={handleSubmit}>
              <Input
                placeholder="Motivo"
                style={{ height: '60px', fontSize: '17px' }}
                value={reason}
                onChange={e => setReason(e.target.value)}
              />
              <Input
                as="textarea"
                placeholder="Explicación detallada del error"
                style={{ flexGrow: 1, fontSize: '14px' }}
                value={content}
                onChange={e => setContent(e.target.value)}
              />
            </Form>
            <div style={{ alignSelf: 'flex-end' }}>
              <BaseButton primary contained onClick={handleSubmit}>
                Enviar
              </BaseButton>
            </div>
          </div>
        </QuestionContainer>
      )}
      <Button primary contained style={{}} onClick={() => setVisible(!visible)}>
        <Icon>{visible ? <Close>X</Close> : <IconBug />}</Icon>
      </Button>
    </FixedBottom>
  )
}

export default BugReports
