import React from 'react'
import { useIsMobile } from '../../../../utils/hooks'
import Browser from './browser'
import Mobile from './mobile'

export const OptionsMenu = props => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return <Mobile {...props} />
  }
  return <Browser {...props} />
}

export default OptionsMenu
