import React from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import NotFound from '../screens/NotFound'

const withAuthorization = ({ validate, notFoundScreen } = {}) => WrappedComponent => {
  const CheckAuth = ({ valid, ...props }) => {
    if (valid) return <WrappedComponent {...props} />
    if (notFoundScreen) return <NotFound />
    return null
  }
  CheckAuth.propTypes = {
    valid: t.bool.isRequired,
    notFoundScreen: t.bool,
  }
  CheckAuth.defaultProps = {
    notFoundScreen: false,
  }
  const mapStateToProps = (...params) => {
    try {
      return { valid: validate(...params) }
    } catch {
      return { valid: false }
    }
  }
  return connect(mapStateToProps)(CheckAuth)
}

export default withAuthorization
