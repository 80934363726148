import React, { useEffect } from 'react'
import withRouter from '../config/router/withRouter'

const mapRouterToProps = ({ location: { pathname } }) => ({ prefetchCurrentPath: pathname })

const prefetch = functionKeys => WrappedComponent => withRouter(mapRouterToProps)(props => {
  const { prefetchCurrentPath } = props
  useEffect(() => {
    functionKeys.forEach(key => props[key]())
  }, [prefetchCurrentPath])
  return <WrappedComponent {...props} />
})

export const usePrefetch = (fetchs, path) => {
  useEffect(() => {
    fetchs.forEach(f => f())
  }, [path])
}

export default prefetch
