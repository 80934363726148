import { toast } from 'react-toastify'
import api from '../../api/index'

const model = {
  state: {},
  reducers: {
    setAll(state, payload) {
      return payload.reduce(
        (all, a) => ({
          ...all,
          [a.id]: a,
        }),
        state,
      )
    },
    set(state, payload) {
      return {
        ...state,
        [payload.id]: payload,
      }
    },
  },
  effects: dispatch => ({
    async getAll(
      _,
      {
        auth: { token },
      },
    ) {
      try {
        const accessCodes = await api.accessCode.getAll(token)
        dispatch.accessCode.setAll(accessCodes)
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async create(
      { email, purchaseId },
      {
        auth: { token, id },
      },
    ) {
      try {
        const user = await api.user.get(token, email)
        const accessCode = await api.accessCode.create(token, {
          accessCode: { purchaseId, responsableId: id, userId: user.id },
        })
        dispatch.accessCode.set(accessCode)
        return accessCode
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async update(
      { email, purchaseId, id },
      {
        auth: { token, id: responsableId },
      },
    ) {
      try {
        const user = await api.user.get(token, email)
        const accessCode = await api.accessCode.update(token, {
          accessCode: {
            purchaseId,
            responsableId,
            userId: user.id,
            id,
          },
          id,
        })
        dispatch.accessCode.set(accessCode)
        return accessCode
      } catch (e) {
        toast.error(`Error al modificar el código de acceso "${id}"`)
        console.error(e)
        return null
      }
    },
    async assign(
      { email, purchaseId, id },
      {
        auth: { token, id: responsableId },
      },
    ) {
      try {
        const accessCode = await api.accessCode.assign(token, {
          accessCode: {
            purchaseId,
            responsableId,
            email,
            id,
          },
          id,
        })
        dispatch.accessCode.set(accessCode)
        return accessCode
      } catch (e) {
        toast.error(`Error al asignar el código de acceso "${id}"`)
        console.error(e)
        return null
      }
    },
    async unassign(
      { id },
      {
        auth: { token },
      },
    ) {
      try {
        const accessCode = await api.accessCode.update(token, {
          accessCode: {
            userId: null,
            id,
          },
          id,
        })
        dispatch.accessCode.set(accessCode)
        return accessCode
      } catch (e) {
        console.error(e)
        return null
      }
    },
  }),
}

export default model
