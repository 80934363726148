import XLSX from 'xlsx'
import { isEmail } from 'validator'

export const readExcel = file => {
  const reader = new FileReader()
  const readFileProcess = new Promise(resolve => {
    reader.onload = e => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' })
      resolve(workbook)
    }
  })
  reader.readAsBinaryString(file)
  return readFileProcess
}

export const createExcelFromMatrix = matrix => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(matrix)
  ws['!cols'] = [
    { width: 45 },
    { width: 20 },
  ]
  XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1')
  return wb
}

export const downloadExcel = (workbook, filename) => {
  XLSX.writeFile(workbook, filename)
}

export const getEmailsFromExcel = async file => {
  const workbook = await readExcel(file)
  const sheet = workbook.Sheets[workbook.SheetNames[0]]
  return Object.values(sheet)
    .filter(e => e.v && isEmail(String(e.v)))
    .map(e => e.v)
}

export const getDataFromExcel = async file => {
  const workbook = await readExcel(file)
  const sheet = workbook.Sheets[workbook.SheetNames[0]]
  return XLSX.utils.sheet_to_json(sheet)
}

export default readExcel
