const defaultTheme = {
  color: {
    default: {
      lighter: '#fff',
      light: '#f0f0f0',
      default: '#9b9b9b',
      dark: '#020203',
    },
    primary: {
      lighter: '#90d3d3',
      light: '#66c1c0',
      default: '#009896',
      dark: '#22716e',
    },
  },
  font: {
    family:
      "'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
    color: {
      default: '#000',
      white: '#fff',
      light: '#979797',
      primary: '#009896',
    },
  },
}

export default defaultTheme
