import { addMonths } from 'date-fns'
import { toast } from 'react-toastify'
import { v4 as uuid } from 'uuid'
import api from '../../api/index'

const model = {
  state: {},
  reducers: {
    setAll(_, payload) {
      return payload.reduce(
        (all, a) => ({
          ...all,
          [a.id]: a,
        }),
        {},
      )
    },
    set(state, payload) {
      return {
        ...state,
        [payload.id]: payload,
      }
    },
  },
  effects: dispatch => ({
    async getAtlases(
      payload,
      {
        auth: { token },
      },
    ) {
      try {
        const atlases = await api.atlas.getAll(token)
        dispatch.atlas.setAll(atlases)
      } catch (e) {
        console.error(e)
      }
    },
    async getAtlas(
      { id },
      {
        auth: { token },
      },
    ) {
      try {
        const { atlas, atlasCategory, anatomicMap } = dispatch
        const showAtlas = await api.atlas.get(id, token)
        atlas.set(showAtlas)
        await atlasCategory.getAll()
        await Promise.all(
          showAtlas.atlasCategories.map(category => atlasCategory.get({ id: category.id })),
        )
        anatomicMap.getAll()
      } catch (e) {
        console.error(e)
      }
    },
    async create(
      { title, description, sections, file, ...data },
      {
        auth: { token, id: responsableId },
      },
    ) {
      try {
        const {
          fileNames: [path],
        } = await api.upload(file)
        const cover = { url: `https://d2gg5obs453f89.cloudfront.net/${path}` }
        const atlas = await api.atlas.create(
          {
            atla: {
              cover,
              atlasCategoriesAttributes: sections.map(({ tag }) => ({ tag })),
              title,
              description,
              organizationId: '001c2841-436d-420e-a44c-7673eb005fda',
              responsableId,
              sibling: uuid(),
              ...data,
            },
          },
          token,
        )
        await api.purchase.create(
          {
            purchase: {
              startDate: new Date(),
              endDate: addMonths(new Date(), 120),
              amount: 0,
              quantity: 1,
              buyerType: 'user',
              buyerId: responsableId,
              atlasId: atlas.id,
              responsableId,
              payment: {},
            },
          },
          token,
        )
        dispatch.atlas.set(atlas)
        return atlas
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async update(
      { id, title, description, sections, file },
      {
        auth: { token, id: responsableId },
      },
    ) {
      try {
        const cover = {}
        if (file && file.length) {
          const {
            fileNames: [path],
          } = await api.upload(file)
          cover.cover = { url: `https://d2gg5obs453f89.cloudfront.net/${path}` }
        }
        const prevSections = sections.filter(section => !section.new)
        const newSections = sections.filter(section => section.new)
        await Promise.all(
          newSections.map(section =>
            api.atlasCategory.create({ tag: section.tag, atlas_id: id }, token),
          ),
        )
        const atlas = await api.atlas.patch(
          {
            id,
            atla: {
              ...cover,
              atlasCategoriesAttributes: prevSections.map(({ id: sectionId, tag }) => ({
                id: sectionId,
                tag,
              })),
              title,
              description,
              organizationId: '001c2841-436d-420e-a44c-7673eb005fda',
              responsableId,
            },
          },
          token,
        )
        dispatch.atlas.set(atlas)
        toast.success('Atlas actualizado correctamente')
        return atlas
      } catch (e) {
        console.error(e)
        toast.error('Error al actualizar atlas')
        return null
      }
    },
    async duplicate(
      { id, language },
      {
        auth: { token },
      },
    ) {
      try {
        await api.atlas.duplicate(id, language, token)
        await dispatch.atlas.getAtlases()
        return true
      } catch (e) {
        return null
      }
    },
    async delete(
      { id },
      {
        auth: { token },
      },
    ) {
      try {
        await api.atlas.delete(id, token)
        await dispatch.atlas.getAtlases()
        return true
      } catch (e) {
        return null
      }
    },
  }),
}

export default model
