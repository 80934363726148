import React, { Component, Fragment } from 'react'
import t from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { withTranslation } from 'react-i18next'
import { Input } from '../../components/form'
import Button from '../../components/button'
import { Title } from '../../components/text'
import { AuthContainer } from '../../components/container'
import TitleHeader from '../../components/header'
import withRouter from '../../config/router/withRouter'
import routes from '../../config/router/routes'

class SignIn extends Component {
  static propTypes = {
    login: t.func.isRequired,
    goSignup: t.func.isRequired,
    redirectAfterLogin: t.func.isRequired,
  }

  state = {
    email: '',
    password: '',
  }

  onWrite = key => ({ target: { value } }) => this.setState({ [key]: value })

  validate = () => {
    const { email, password } = this.state
    return email !== '' && password !== ''
  }

  onSubmit = async e => {
    e.preventDefault()
    if (this.validate()) {
      const { login, redirectAfterLogin } = this.props
      if (await login(this.state)) {
        redirectAfterLogin()
      }
    } else {
      toast.error('Hay errores en el formulario. Revisa que tengas todos los campos llenados')
    }
  }

  render() {
    const { email, password } = this.state
    const { goSignup, t } = this.props
    return (
      <Fragment>
        <TitleHeader title={t('signinTitle')} />
        <AuthContainer>
          <Title>{t('signin')}</Title>
          <form onSubmit={this.onSubmit}>
            <Input
              type="email"
              placeholder="user@email.com"
              value={email}
              onChange={this.onWrite('email')}
            />
            <Input
              type="password"
              placeholder="*****"
              value={password}
              onChange={this.onWrite('password')}
            />
            <Button primary contained>
              {t('signin')}
            </Button>
            <Button onClick={() => goSignup()}>{t('signup')}</Button>
          </form>
        </AuthContainer>
      </Fragment>
    )
  }
}

const mapDispatchToProps = ({ auth }) => ({
  login: data => auth.login(data),
})
const mapRouterToProps = ({ history: { push }, location: { search } }) => {
  const params = new URLSearchParams(search)
  return {
    goSignup: () => push(routes.signup),
    redirectAfterLogin: () => push(params.has('redirect') ? params.get('redirect') : routes.home),
  }
}

export default withTranslation('auth')(
  withRouter(mapRouterToProps)(connect(null, mapDispatchToProps)(SignIn)),
)
