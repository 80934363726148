export const realToFlattenTranslations = (translation, prefix = '') => {
  const output = Object.entries(translation).reduce((all, [key, val]) => {
    const outKey = `${prefix ? `${prefix}.` : ''}${key}`
    if (typeof val !== 'object') return { ...all, [outKey]: val }
    return { ...all, ...realToFlattenTranslations(val, outKey) }
  }, {})

  return output
}

export const flattenToRealTranslations = flattenedTranslations => {
  const output = Object.entries(flattenedTranslations).reduce((all, [key, val]) => {
    const keys = key.split('.')
    let currentObject = all
    keys.slice(0, -1).forEach(k => {
      if (!currentObject[k]) currentObject[k] = {}
      currentObject = currentObject[k]
    })
    const lastKey = keys.slice(-1)[0]
    currentObject[lastKey] = val
    return all
  }, {})
  return output
}
