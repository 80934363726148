import styled from '@emotion/styled'

export const ContainerIcon = styled.span`
  fill: ${props => (props.active ? props.theme.color.primary.default : props.theme.color.default.default)};
  ${props => (props.fill ? `fill: ${props.fill};` : '')}
  ${props => (props.onClick
    ? `
  cursor: pointer;
  :hover {
    fill: ${props.theme.color.primary.light}
  }
  `
    : '')}
`

export default { ContainerIcon }
