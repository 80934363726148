import React from 'react'
import t from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row as BaseRow } from '../../components/container'
import { Img as BaseImg } from '../../components/image'
import { Content, Title } from '../../components/text'
import withRouter from '../../config/router/withRouter'
import Button from '../../components/button'
import routes from '../../config/router/routes'

const Row = styled(BaseRow)`
  margin: 90px 0;
  & > * {
    padding: 0 25px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const Img = styled(BaseImg)`
  width: 640px;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const StartContainer = styled.div`
  align-self: flex-start;
`

const AtlasCover = ({ cover, title, description, purchased, goPurchases }) => {
  const { t } = useTranslation('atlas')
  return (
    <>
      <Row fullWidth>
        <Img
          src={
            cover && cover.url
              ? cover.url
              : 'https://sightlinemediaentertainment.com/wp-content/uploads/2015/09/placeholder-cover.jpg'
          }
        />
        <StartContainer>
          <Title>{title}</Title>
          <Content>{description}</Content>
        </StartContainer>
      </Row>
      {purchased && (
        <div>
          <Button onClick={goPurchases}>{t('reviewAccessCodes')}</Button>
        </div>
      )}
    </>
  )
}
AtlasCover.propTypes = {
  cover: t.string,
  title: t.string.isRequired,
  description: t.string.isRequired,
  purchased: t.bool.isRequired,
  goPurchases: t.func.isRequired,
}
AtlasCover.defaultProps = {
  cover: '',
}

const mapStateToProps = ({ atlas, purchase, auth: { id: userId } }, { atlasId }) => ({
  ...atlas[atlasId],
  purchased: Object.values(purchase).some(element => element.responsableId === userId),
})

export const ConnectedAtlasCover = withRouter(
  ({
    match: {
      params: { atlasId },
    },
    history: { push },
  }) => ({
    atlasId,
    goPurchases: () => push(routes.atlasPurchases(atlasId)),
  }),
)(connect(mapStateToProps)(AtlasCover))

export default AtlasCover
