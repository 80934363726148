import { createMuiTheme } from '@material-ui/core/styles'
import emotionTheme from '../emotion/themes'

const defaultTheme = createMuiTheme({
  ...emotionTheme,
  palette: {
    primary: {
      main: '#009896',
    },
    info: {
      main: '#009896',
    },
  },
})

export default defaultTheme
