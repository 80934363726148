import React from 'react'
import types from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/modal'
import { Row as BaseRow } from '../../../components/container'
import { Img as BaseImg } from '../../../components/image'
import { Content, Title, SubTitle } from '../../../components/text'
import Button from '../../../components/button'
import PayPalButton from '../../../components/payment/PayPalButton'
import withRouter from '../../../config/router/withRouter'
import routes from '../../../config/router/routes'

const StartContainer = styled.div`
  align-self: flex-start;
  width: 100%;
`

const Row = styled(BaseRow)`
  margin-top: 45px;
  & > * {
    padding: 0 25px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const SmallImg = styled(BaseImg)`
  width: 320px;
  height: auto;
  @media (max-width: 768px) {
    width: 50%;
  }
`

export const BuyModal = ({
  modal,
  setModal,
  title,
  description,
  cover,
  biannualAmount,
  annualAmount,
  biannualPrice,
  annualPrice,
  purchase,
  atlasId,
  goPurchase,
}) => {
  const quantity = modal === 'biannual' ? biannualAmount : annualAmount
  const price = modal === 'biannual' ? biannualPrice * biannualAmount : annualPrice * annualAmount
  const months = modal === 'biannual' ? 6 : 12
  const doPurchase = async (...data) => {
    const { id: purchaseId } = await purchase(...data)
    goPurchase({ purchaseId })
  }
  const { t } = useTranslation('storeAtlas')
  return (
    <Modal show={modal} onClose={() => setModal(null)}>
      <div>
        <Row fullWidth>
          <SmallImg
            src={
              cover && cover.url
                ? cover.url
                : 'https://sightlinemediaentertainment.com/wp-content/uploads/2015/09/placeholder-cover.jpg'
            }
          />
          <StartContainer>
            <Title>{title}</Title>
            <SubTitle light>{t('description')}:</SubTitle>
            <Content>{description}</Content>
            <hr />
            <SubTitle primary center>
              {t('confirmMessage', { quantity, price, months })}
            </SubTitle>
            {price ? (
              <>
                <SubTitle light center>
                  {t('selectPaymentMethod')}
                </SubTitle>
                <BaseRow>
                  <PayPalButton
                    total={price * quantity}
                    createPurchase={payment =>
                      doPurchase({
                        type: modal,
                        amount: price * quantity,
                        quantity,
                        atlasId,
                        payment,
                      })
                    }
                  />
                </BaseRow>
              </>
            ) : (
              <>
                <Button
                  onClick={() =>
                    doPurchase({
                      type: modal,
                      amount: price * quantity,
                      quantity,
                      atlasId,
                    })
                  }
                  contained
                  primary
                  center
                >
                  {t('buy')}
                </Button>
              </>
            )}
          </StartContainer>
        </Row>
      </div>
    </Modal>
  )
}

BuyModal.propTypes = {
  cover: types.string,
  atlasId: types.string.isRequired,
  title: types.string.isRequired,
  description: types.string.isRequired,
  biannualPrice: types.number.isRequired,
  annualPrice: types.number.isRequired,
  modal: types.string.isRequired,
  setModal: types.func.isRequired,
  biannualAmount: types.number.isRequired,
  annualAmount: types.number.isRequired,
  purchase: types.func.isRequired,
  goPurchase: types.func.isRequired,
}
BuyModal.defaultProps = {
  cover: '',
}

const mapDispatchToProps = ({ purchase: { create } }) => ({
  purchase: ({ type, amount, quantity, atlasId, payment }) =>
    create({
      type,
      amount,
      quantity,
      atlasId,
      payment,
    }),
})

export default withRouter(({ match: { params: { atlasId } }, history: { push } }) => ({
  atlasId,
  goPurchase: ({ purchaseId }) => push(routes.atlasPurchase(atlasId, purchaseId)),
}))(
  connect(
    null,
    mapDispatchToProps,
  )(BuyModal),
)
