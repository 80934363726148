const routes = {
  home: '/',
  signin: '/signin',
  signup: '/signup',
  users: '/users',
  atlas: (id = ':atlasId') => `/atlas/${id}`,
  atlasCreate: '/atlas/create',
  atlasEdit: (id = ':atlasId') => `/atlas/${id}/edit`,
  atlasEditMaps: (id = ':atlasId') => `/atlas/${id}/edit/maps`,
  anatomicMap: (atlasId = ':atlasId', amId = ':anatomicMapId') =>
    `/atlas/${atlasId}/anatomic-map/${amId}`,
  anatomicMapModel: (atlasId = ':atlasId', amId = ':anatomicMapId') =>
    `/atlas/${atlasId}/anatomic-map/${amId}`,
  anatomicMapRadiography: (atlasId = ':atlasId', amId = ':anatomicMapId') =>
    `/atlas/${atlasId}/anatomic-map/${amId}/radiography`,
  anatomicMapVideos: (atlasId = ':atlasId', amId = ':anatomicMapId') =>
    `/atlas/${atlasId}/anatomic-map/${amId}/videos`,
  anatomicMapImages: (atlasId = ':atlasId', amId = ':anatomicMapId') =>
    `/atlas/${atlasId}/anatomic-map/${amId}/images`,
  atlasPurchases: (id = ':atlasId') => `/atlas/${id}/purchases`,
  atlasPurchase: (id = ':atlasId', purchaseId = ':purchaseId') =>
    `/atlas/${id}/purchases/${purchaseId}`,
  store: '/store',
  storeAtlas: (id = ':atlasId') => `/store/${id}`,
  languages: '/languages',
}

export default routes
