import React, { Children, cloneElement } from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

export const MenuElement = styled.li`
  font-family: ${props => props.theme.font.family};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  background-color: ${props => props.theme.color.primary[props.selected ? 'lighter' : 'light']};
  border: ${({ noBorder }) => (noBorder ? 'none' : '1px solid white')};
  color: ${props => props.theme.font.color.white};
  fill: ${props => props.theme.font.color.white};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  ${props => (props.dropdown
    ? `
    position: relative;
    ul {
      max-height: 300px;
      overflow-y: auto;
      display: none;
      position: absolute;
      z-index: 1;
      width: 100%;
    }
    :hover ul {
      display: block;
    }
  `
    : '')};
  :hover {
    background-color: ${props => props.theme.color.primary.lighter};
  }
  ${props => (props.transparent && props.selected
    ? `color: ${props.theme.color.primary.default} !important;
    fill: ${props.theme.color.primary.default} !important;`
    : '')}
  ${({ flex }) => (flex ? `flex: ${flex};` : '')}
  ${({ noSpace }) => (noSpace ? 'white-space:nowrap;' : '')}
`

const MenuComponent = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: ${({ vertical }) => (vertical ? 'column' : 'row')} nowrap;
  justify-content: flex-start;
  ${({ padding }) => (padding ? `padding: ${padding} !important;` : '')}
  & > * {
    flex: 1;
    padding: 10px 0;
  }
  ${({ transparent, theme }) => (transparent
    ? `
    li {
      background: transparent;
      color: ${theme.color.default.default};
      fill: ${theme.color.default.default};
    :hover {
      background: transparent;
      color: ${theme.color.primary.default};
      fill: ${theme.color.primary.default};
    }
  }
`
    : '')}
`

export const Menu = withTheme(({
  children, vertical, transparent, theme, ...props
}) => (
  <MenuComponent vertical={vertical} transparent={transparent} {...props}>
    {Children.map(children, child => cloneElement(child, { transparent }))}
  </MenuComponent>
))

export default { MenuElement, Menu }
