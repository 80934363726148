import React, { Component } from 'react'
import t from 'prop-types'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { FileInput, Input } from '../../components/form'
import Button from '../../components/button'
import numberIdGenerator from '../../utils/ids'
import { FullLoader } from '../../components/loader'

const Form = styled.form`
  * {
    margin: 20px 0;
  }
  textarea {
    padding: 20px;
  }
  & > div {
    width: 70%;
    * {
      width: 50%;
    }
  }
`

const SectionsContainer = styled.div`
  width: 100%;
  display: flex;
  div {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    input {
      width: 35%;
      margin: 0 20px 20px 0;
    }
  }
  button {
    flex: 0;
    white-space: pre;
  }
`
class AtlasForm extends Component {
  id = numberIdGenerator(
    this.props.atlas.atlasCategories
      ? this.props.atlas.atlasCategories
          .map(category => category.id)
          .sort()
          .reverse()[0] + 1
      : 0,
  )

  static propTypes = {
    handleSubmit: t.func.isRequired,
    atlas: t.object,
  }

  static defaultProps = {
    atlas: {
      title: '',
      description: '',
    },
  }

  state = {
    id: this.props.atlas.id,
    title: this.props.atlas.title,
    description: this.props.atlas.description,
    sections: this.props.atlas.atlasCategories || [{ id: this.id.next(), tag: '', new: true }],
    files: [],
    loading: false,
  }

  onFileChange = ({ target: { files } }) => {
    this.setState({ files })
  }

  onTitleChange = ({ target: { value: title } }) => {
    this.setState({ title })
  }

  onDescriptionChange = ({ target: { value: description } }) => {
    this.setState({ description })
  }

  addSection = e => {
    e.preventDefault()
    this.setState(({ sections }) => ({
      sections: [...sections, { id: this.id.next().value, tag: '', new: true }],
    }))
  }

  onSectionChange = (id, { target: { value: tag } }) => {
    const { sections } = this.state
    const index = sections.findIndex(section => section.id === id)
    this.setState({
      sections: [
        ...sections.slice(0, index),
        { ...sections[index], tag },
        ...sections.slice(index + 1),
      ],
    })
  }

  onSubmit = async e => {
    e.preventDefault()
    this.setState({ loading: true })
    try {
      if (this.validate()) {
        const { handleSubmit } = this.props
        const { files } = this.state
        await handleSubmit({ ...this.state, file: files[0] })
      } else {
        toast.error('Hay errores en tus datos. Revisa que tengas todos los campos rellenados')
      }
    } catch {
      toast.error('Hay errores en tus datos. Revisa que tengas todos los campos rellenados')
    } finally {
      this.setState({ loading: false })
    }
  }

  validate = () => {
    const { id, title, files, sections, description } = this.state
    return (
      title !== '' &&
      description !== '' &&
      (files.length > 0 || id) &&
      sections.length > 0 &&
      sections.every(s => s.tag !== '')
    )
  }

  render() {
    const { update } = this.props
    const { title, sections, files, description, loading } = this.state
    return (
      <>
        {loading && <FullLoader />}
        <Form onSubmit={this.onSubmit}>
          <div>
            <FileInput
              file={files}
              onChange={this.onFileChange}
              accept="image/png,image/jpg,image/jpeg"
            />
            <Input placeholder="Nombre del Atlas" value={title} onChange={this.onTitleChange} />
            <Input
              as="textarea"
              placeholder="Descripción del Atlas"
              value={description}
              onChange={this.onDescriptionChange}
            />
          </div>
          <SectionsContainer>
            <div>
              {sections.map(({ id, tag }) => (
                <Input
                  key={id}
                  placeholder="Nombre Sección"
                  value={tag}
                  onChange={e => this.onSectionChange(id, e)}
                />
              ))}
              <Button primary onClick={this.addSection}>
                Añadir otra sección
              </Button>
            </div>
          </SectionsContainer>
          <Button primary contained>
            {update ? 'Actualizar' : 'Siguiente'}
          </Button>
        </Form>
      </>
    )
  }
}

export default AtlasForm
