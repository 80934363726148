import axios from 'axios'
import { camelizeObject, snakeObject } from '../../utils/case'

export default class Api {
  constructor(baseUrl, token = '') {
    this.baseUrl = baseUrl
    this.token = token
  }

  request = async request => {
    try {
      const response = await request
      return camelizeObject(response.data)
    } catch (err) {
      throw err
    }
  }

  url = url => `${this.baseUrl}${url}`

  generateHeader = () => ({
    'Content-Type': 'application/json',
    Authorization: this.token !== '' ? `Bearer ${this.token}` : null,
  })

  withToken = token => new Api(this.baseUrl, token)

  generateInstance = () => {
    const instance = axios.create({
      baseURL: this.baseUrl,
      headers: this.generateHeader(),
    })
    // instance.interceptors.request.use(r => {
    //   console.log(r)
    //   return r
    // })
    // instance.interceptors.response.use(r => {
    //   console.log(r)
    //   return r
    // })
    return instance
  }

  generateMultiPartInstance = () =>
    axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  get = async (url, params) => this.request(this.generateInstance().get(url, { params }))

  post = async (url, body, config = {}) =>
    this.request(this.generateInstance().post(url, snakeObject(body), config))

  noSnakePost = async (url, body, config = {}) =>
    this.request(this.generateInstance().post(url, body, config))

  del = async url => this.request(this.generateInstance().delete(url))

  put = async (url, body) => this.request(this.generateInstance().put(url, snakeObject(body)))

  noSnakePut = async (url, body) => this.request(this.generateInstance().put(url, body))

  patch = async (url, body) => this.request(this.generateInstance().patch(url, snakeObject(body)))

  uploadFile = (file, cb = () => {}) => {
    const formData = new FormData()
    formData.append('file', file)
    return this.request(
      this.generateMultiPartInstance().post('', formData, { onUploadProgress: cb }),
    )
  }
}
