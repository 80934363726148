import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import t from 'prop-types'
import styled from '@emotion/styled'

import prefetch from '../../../utils/prefetch'
import withRouter from '../../../config/router/withRouter'
import { Title as BaseTitle } from '../../../components/text'
import { ConnectedModel as Model } from './Model'
import Radiography from './Radiography'
import Video from './Video'
import Images from './Images'
import routes from '../../../config/router/routes'
import OptionsMenu from './OptionsMenu'
import { Row } from '../../../components/container'
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg'
import { ContainerIcon } from '../../../components/icon'
import Button from '../../../components/button'

const Container = styled.div`
  margin: 0 30px;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const Icon = styled(ContainerIcon)`
  svg {
    width: 30px;
    height: 30px;
    margin: 10px;
  }
`

const Title = styled(BaseTitle)``
const ContentContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const NoData = () => {
  const loading = useSelector(state => state.loading.models.anatomicMap)
  if (loading) return <h1>Loading</h1>
  return <h1>Not found</h1>
}

const AnatomicMap = ({ name, description, url: model, video, img, radio, anatomicMapId }) => {
  const [visible, setVisible] = useState(false)
  const [forceNull, setForceNull] = useState(false)
  const textareaRef = useRef()
  const handleShow = () => {
    setVisible(true)
  }
  useLayoutEffect(() => {
    if (visible) {
      textareaRef.current.select()
      document.execCommand('copy')
    }
  }, [visible])
  useEffect(() => {
    setVisible(false)
  }, [model])
  const loading = useSelector(state => state.loading.models.anatomicMap)
  useEffect(() => {
    setForceNull(true)
    setTimeout(() => {
      setForceNull(false)
    }, 400)
  }, [anatomicMapId])
  if (forceNull) return null
  return (
    <Container>
      {!loading && (
        <Row>
          <Title>{name}</Title>
          <Button onClick={handleShow}>
            <Icon>
              <ShareIcon />
            </Icon>
          </Button>
          {visible && (
            <textarea
              ref={textareaRef}
              value={`<iframe src="https://static.app.medicinehub.cloud/${anatomicMapId}?viewOption=3" width="800px" height="400px"></iframe>`}
            />
          )}
        </Row>
      )}
      <OptionsMenu
        model={model}
        radio={radio && radio.length}
        video={video && video.length}
        img={img && img.length}
      />
      <ContentContainer>
        <Switch>
          {model && <Route component={Model} path={routes.anatomicMapModel()} exact />}
          {radio && radio.length && (
            <Route component={Radiography} path={routes.anatomicMapRadiography()} exact />
          )}
          {video && video.length && (
            <Route component={Video} path={routes.anatomicMapVideos()} exact />
          )}
          {img && img.length && (
            <Route component={Images} path={routes.anatomicMapImages()} exact />
          )}
          <Route render={() => <NoData />} />
        </Switch>
      </ContentContainer>
    </Container>
  )
}
AnatomicMap.propTypes = {
  name: t.string.isRequired,
  description: t.string.isRequired,
  url: t.string.isRequired,
  img: t.string,
  video: t.string,
  radio: t.string,
}
AnatomicMap.defaultProps = { img: null, video: null, radio: null }

const mapStateToProps = ({ anatomicMap }, { anatomicMapId: id }) => ({ ...anatomicMap[id] })

const mapDispatchToProps = ({ anatomicMap }, { anatomicMapId: id }) => ({
  getMap: () => anatomicMap.get({ id }),
})

const mapRouterToProps = ({
  match: {
    params: { anatomicMapId },
  },
}) => ({
  anatomicMapId,
})

export const ConnectedAnatomicMap = withRouter(mapRouterToProps)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(prefetch(['getMap'])(AnatomicMap)),
)

export default AnatomicMap
