import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
/* eslint-disable */
const isPlainObject = function(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}
/* eslint-enable */

const caseTransformObject = transform => object => {
  if (isPlainObject(object)) {
    return Object.keys(object).reduce((all, key) => {
      const transformedKey = transform(key)
      return {
        ...all,
        [transformedKey]: caseTransformObject(transform)(object[key]),
      }
    }, {})
  }
  if (Array.isArray(object)) {
    return object.map(caseTransformObject(transform))
  }
  return object
}

const hotfixCamelCase = key => {
  if (key === 'm_3_d') return 'm3d'
  return camelCase(key)
}

const hotfixSnakeCase = key => {
  if (key === 'm3D' || key === 'm3d') return 'm3d'
  return snakeCase(key)
}

export const camelizeObject = caseTransformObject(hotfixCamelCase)
export const snakeObject = caseTransformObject(hotfixSnakeCase)
