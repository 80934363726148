import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import types from 'prop-types'
import { useTranslation, withTranslation } from 'react-i18next'
import { Container, Row } from '../../components/container'
import Button from '../../components/button'
import TitleHeader from '../../components/header'
import prefetch from '../../utils/prefetch'
import withRouter from '../../config/router/withRouter'
import routes from '../../config/router/routes'
import { ConnectedAtlasCover as AtlasCover } from './AtlasCover'
import { ConnectedAnatomicMap as AnatomicMap } from './AnatomicMap'
import AtlasCategoriesMenu from './AtlasCategoriesMenu'
import { useIsMobile } from '../../utils/hooks'
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg'
import withAuthorization from '../../components/Authorization'
import Purchases from './Purchases'

const EditButton = withRouter(({ match: { params: { atlasId } } }) => ({
  atlasId,
}))(
  withAuthorization({
    validate: (store, ownProps) => store.atlas[ownProps.atlasId].responsableId === store.auth.id,
  })(({ onClick }) => {
    const { t } = useTranslation('atlas')
    return (
      <Button onClick={onClick} contained primary>
        {t('edit')}
      </Button>
    )
  }),
)

const Atlas = ({ title, atlasCategories, goAtlas, selectedCategory, goEditAtlas, purchased }) => {
  const [showMenu, setShowMenu] = useState(false)
  const isMobile = useIsMobile()
  return (
    <>
      <TitleHeader
        title={title}
        onTitleClick={goAtlas}
        RightComponent={() => (
          <Row>
            <EditButton onClick={goEditAtlas} />
            {isMobile && (
              <Button contained primary onClick={() => setShowMenu(!showMenu)}>
                <MenuIcon fill="white" width="30px" />
              </Button>
            )}
          </Row>
        )}
      />
      <AtlasCategoriesMenu
        atlasCategories={atlasCategories}
        selectedCategory={selectedCategory}
        enabled={showMenu}
        hide={() => setShowMenu(false)}
      />

      <Container>
        <Switch>
          <Route path={routes.atlasPurchase()} component={Purchases} />
          <Route path={routes.atlasPurchases()} component={Purchases} />
          <Route path={routes.anatomicMap()} component={AnatomicMap} />
          <Route path={routes.atlas()} component={AtlasCover} exact />
        </Switch>
      </Container>
    </>
  )
}
Atlas.propTypes = {
  title: types.string.isRequired,
  atlasCategories: types.arrayOf(
    types.shape({
      tag: types.string.isRequired,
    }),
  ),
  goAtlas: types.func.isRequired,
  goEditAtlas: types.func.isRequired,
  selectedCategory: types.shape({ id: types.number.isRequired }),
}
Atlas.defaultProps = {
  atlasCategories: [],
  selectedCategory: {},
}

const mapRouterToProps = ({
  match: {
    params: { atlasId: id },
  },
  history: { push },
  location: { pathname },
}) => ({
  anatomicMapId: pathname.split('/')[4],
  id,
  goAnatomicMap: mapId => push(routes.anatomicMap(id, mapId)),
  goEditAtlas: () => push(routes.atlasEditMaps(id)),
  goAtlas: () => push(routes.atlas(id)),
})

const mapStateToProps = (
  { atlasCategory, atlas, anatomicMap },
  { id, anatomicMapId, i18n, history },
) => {
  const currentAtlas = atlas[id] || {}
  const language = i18n.language || 'es'
  if (currentAtlas.sibling && currentAtlas.language && currentAtlas.language !== language) {
    const languageAtlas = Object.values(atlas)
      .filter(a => a.sibling === currentAtlas.sibling)
      .find(a => a.language.match(new RegExp(language, 'i')))
    if (languageAtlas && id !== languageAtlas.id && anatomicMapId) {
      const currentMap = anatomicMap[anatomicMapId]
      const siblingsMaps = Object.values(anatomicMap).filter(
        map => currentMap.sibling === map.sibling,
      )
      if (siblingsMaps.length > 1) {
        history.push(
          routes.anatomicMap(
            languageAtlas.id,
            siblingsMaps.find(map => map.id !== anatomicMapId).id,
          ),
        )
      } else {
        history.push(routes.atlas(languageAtlas.id))
      }
    } else if (languageAtlas && id !== languageAtlas.id) {
      history.push(routes.atlas(languageAtlas.id))
    }
  }
  return {
    ...currentAtlas,
    atlasCategories: Object.values(atlasCategory).filter(a => a.atlasId === id),
    selectedCategory: anatomicMapId
      ? Object.values(atlasCategory).find(a =>
          a.anatomicMaps.map(m => m.id).includes(anatomicMapId),
        )
      : null,
  }
}

const mapDispatchToProps = (
  { atlas: { getAtlas }, purchase, anatomicMap },
  { id, goAnatomicMap },
) => ({
  getAtlas: () => getAtlas({ id }),
  getPurchases: () => purchase.getAll(),
  goAnatomicMap: mapId => {
    anatomicMap.get({ id: mapId })
    goAnatomicMap(mapId)
  },
})

export const connectedAtlas = withTranslation()(
  withRouter(mapRouterToProps)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(prefetch(['getAtlas', 'getPurchases'])(Atlas)),
  ),
)

export default Atlas
