import React, { useEffect, useRef, useState } from 'react'
import uuid from 'uuid/v4'
import dwv from 'dwv'
import './dwv.css'

// gui overrides

// decode query
dwv.utils.decodeQuery = dwv.utils.base.decodeQuery
// progress
dwv.gui.displayProgress = function() {}
// get element
dwv.gui.getElement = dwv.gui.base.getElement
// refresh element
dwv.gui.refreshElement = dwv.gui.base.refreshElement

// Image decoders (for web workers)
dwv.image.decoderScripts = {
  jpeg2000: '/assets/dwv/decoders/pdfjs/decode-jpeg2000.js',
  'jpeg-lossless': '/assets/dwv/decoders/rii-mango/decode-jpegloss.js',
  'jpeg-baseline': '/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js',
  rle: '/assets/dwv/decoders/dwv/decode-rle.js',
}

const TOOLS = ['Scroll', 'ZoomAndPan', 'WindowLevel', 'Draw']
const DWV = ({ url }) => {
  const dwvApp = useRef()
  const id = useRef(uuid())
  const [dwvLoaded, setDwvLoaded] = useState(false)
  const [progress, setProgress] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [selectedTool, setTool] = useState('Scroll')
  const setSelectedTool = selected => {
    setTool(selected)
    dwvApp.current.onChangeTool({ currentTarget: { value: selected } })
  }
  useEffect(() => {
    // create app
    const app = new dwv.App()
    // initialise app
    app.init({
      containerDivId: id.current,
      tools: TOOLS,
      shapes: ['Ruler'],
    })
    app.addEventListener('load-progress', event => {
      setProgress(event.loaded)
    })
    app.addEventListener('load-end', () => {
      // set data loaded flag
      setLoaded(true)
      // set the selected tool
      if (app.isMonoSliceData() && app.getImage().getNumberOfFrames() === 1) {
        setSelectedTool('ZoomAndPan')
      } else {
        setSelectedTool('Scroll')
      }
    })
    // store
    dwvApp.current = app
    setDwvLoaded(true)
    return () => {
      window.onresize = null
      app.reset()
    }
  }, [url])
  useEffect(() => {
    if (dwvApp.current && url && url.length) {
      dwvApp.current.loadURLs(Array.isArray(url) > 0 ? url : [url], [
        { name: 'no-cors', value: 'true' },
      ])
    }
  }, [url, dwvLoaded])
  return (
    <div>
      <select onChange={e => setSelectedTool(e.target.value)} value={selectedTool}>
        {TOOLS.map(tool => (
          <option key={tool} value={tool}>
            {tool}
          </option>
        ))}
      </select>
      <div id={id.current} className="dwv-container">
        <div className="layerContainer">
          <canvas className="imageLayer">Only for HTML5 compatible browsers...</canvas>
          <div className="drawDiv" />
        </div>
      </div>
    </div>
  )
}

export default DWV
