import ReactGA from 'react-ga'
import withRouter from '../../config/router/withRouter'

const useGoogleAnalitycs = () => {
  if (process.env.REACT_APP_ANALYTICS_ID) ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID)
}

export default useGoogleAnalitycs

export const Tracker = withRouter(({ location }) => ({ location }))(({ location }) => {
  if (process.env.REACT_APP_ANALYTICS_ID) ReactGA.pageview(location.pathname + location.search)
  return null
})
