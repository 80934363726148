import React, { useState } from 'react'
import types from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row as BaseRow } from '../../../components/container'
import { Img as BaseImg } from '../../../components/image'
import { Content, Title, SubTitle } from '../../../components/text'
import withRouter from '../../../config/router/withRouter'
import Button from '../../../components/button'
import { Input } from '../../../components/form'
import BuyModal from './BuyModal'

const Row = styled(BaseRow)`
  margin-top: 45px;
  & > * {
    padding: 0 25px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const Img = styled(BaseImg)`
  width: 640px;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StartContainer = styled.div`
  align-self: flex-start;
  width: 100%;
`

export const AtlasCover = ({
  cover,
  title,
  description,
  tags,
  responsable,
  biannualPrice,
  annualPrice,
}) => {
  const [biannualAmount, setBiannual] = useState(0)
  const [annualAmount, setAnnual] = useState(0)
  const [modal, setModal] = useState(null)
  const { t } = useTranslation('storeAtlas')
  return (
    <>
      <Row fullWidth>
        <Img
          src={
            cover && cover.url
              ? cover.url
              : 'https://sightlinemediaentertainment.com/wp-content/uploads/2015/09/placeholder-cover.jpg'
          }
        />
        <StartContainer>
          <Title>{title}</Title>
          <SubTitle light>{t('description')}:</SubTitle>
          <Content>{description}</Content>
          <SubTitle light>{t('author')}:</SubTitle>
          <Content>{responsable}</Content>
          <SubTitle light>{t('tags')}:</SubTitle>
          <Content>
            {tags && tags.length
              ? tags.map((tag, i, all) => `${tag}${all.length - 1 !== i ? ', ' : ''}`)
              : t('noTags')}
          </Content>
          <hr />
          <SubTitle primary>{t('biannual')}</SubTitle>
          <BaseRow fullWidth justifyContent="space-between">
            <Title primary noMargin>
              ${biannualPrice || '0'} CLP
            </Title>
            <BaseRow>
              <Input
                type="number"
                value={biannualAmount}
                onChange={({ target: { valueAsNumber } }) => setBiannual(valueAsNumber)}
              />
              <Button
                contained
                disabled={!(biannualAmount > 0)}
                primary
                onClick={() => setModal('biannual')}
              >
                {t('buy')}
              </Button>
            </BaseRow>
          </BaseRow>
          <hr />
          <SubTitle primary>{t('annual')}</SubTitle>
          <BaseRow fullWidth justifyContent="space-between">
            <Title primary noMargin>
              ${annualPrice || '0'} CLP
            </Title>
            <BaseRow>
              <Input
                type="number"
                value={annualAmount}
                onChange={({ target: { valueAsNumber } }) => setAnnual(valueAsNumber)}
              />
              <Button
                contained
                disabled={!(annualAmount > 0)}
                primary
                onClick={() => setModal('annual')}
              >
                {t('buy')}
              </Button>
            </BaseRow>
          </BaseRow>
        </StartContainer>
      </Row>
      <BuyModal
        modal={modal}
        setModal={setModal}
        title={title}
        description={description}
        cover={cover}
        biannualAmount={biannualAmount}
        annualAmount={annualAmount}
        biannualPrice={biannualPrice}
        annualPrice={annualPrice}
      />
    </>
  )
}
AtlasCover.propTypes = {
  cover: types.string,
  title: types.string.isRequired,
  description: types.string.isRequired,
  responsable: types.string.isRequired,
  tags: types.arrayOf(types.string),
  biannualPrice: types.number.isRequired,
  annualPrice: types.number.isRequired,
}
AtlasCover.defaultProps = {
  cover: '',
  tags: [],
}

const mapStateToProps = ({ atlas }, { atlasId }) => ({ ...atlas[atlasId] })

export default withRouter(({ match: { params: { atlasId } } }) => ({
  atlasId,
}))(connect(mapStateToProps)(AtlasCover))
