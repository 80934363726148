import React from 'react'
import types from 'prop-types'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import routes from '../../../../config/router/routes'

import { Menu as BaseMenu, MenuElement } from '../../../../components/menu'

import { ReactComponent as IconDescription } from '../../../../assets/icons/description.svg'
import { ReactComponent as IconImage } from '../../../../assets/icons/image.svg'
import { ReactComponent as IconRadio } from '../../../../assets/icons/radio.svg'
import { ReactComponent as IconVideo } from '../../../../assets/icons/video.svg'
import { ReactComponent as IconModel3d } from '../../../../assets/icons/3d.svg'
import withRouter from '../../../../config/router/withRouter'

const Menu = styled(BaseMenu)`
  li {
    text-align: left;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    * {
      padding: 10px;
    }
    span {
      flex: 1;
    }
    div {
      flex: 0;
    }
  }
`
const OptionsMenu = ({
  description,
  model,
  radio,
  video,
  img,
  atlasId,
  anatomicMapId,
  path,
  push,
}) => {
  const { t } = useTranslation('atlas')
  return (
    <Menu transparent>
      {[
        {
          Icon: IconDescription,
          text: t('description'),
          path: routes.anatomicMap(atlasId, anatomicMapId),
          itHas: description,
        },
        {
          Icon: IconModel3d,
          text: t('3d'),
          path: routes.anatomicMapModel(atlasId, anatomicMapId),
          itHas: model,
        },
        {
          Icon: IconRadio,
          text: t('radiography'),
          path: routes.anatomicMapRadiography(atlasId, anatomicMapId),
          itHas: radio,
        },
        {
          Icon: IconVideo,
          text: t('videos'),
          path: routes.anatomicMapVideos(atlasId, anatomicMapId),
          itHas: video,
        },
        {
          Icon: IconImage,
          text: t('images'),
          path: routes.anatomicMapImages(atlasId, anatomicMapId),
          itHas: img,
        },
      ]
        .filter(({ itHas }) => itHas)
        .map(({ Icon, text, path: elementPath }) => (
          <MenuElement
            key={text}
            selected={path === elementPath}
            onClick={() => push(elementPath)}
            flex="0"
            noSpace
          >
            <div>
              <Icon width={30} height={30} />
            </div>
            <span>{text}</span>
          </MenuElement>
        ))}
    </Menu>
  )
}

OptionsMenu.propTypes = {
  description: types.string.isRequired,
  model: types.string.isRequired,
  radio: types.string,
  video: types.string,
  img: types.string,
  atlasId: types.string.isRequired,
  anatomicMapId: types.string.isRequired,
  path: types.string.isRequired,
  push: types.func.isRequired,
}
OptionsMenu.defaultProps = {
  radio: null,
  video: null,
  img: null,
}
const mapRouterToProps = ({
  history: { push },
  location: { pathname },
  match: {
    params: { atlasId, anatomicMapId },
  },
}) => ({
  push,
  path: pathname,
  atlasId,
  anatomicMapId,
})

export default withRouter(mapRouterToProps)(OptionsMenu)
